import axios from "axios";

export default {
  namespaced: true,
  state: {
    base_url: process.env.VUE_APP_BASE_API_URL,
    isDynamicSectionStatusChanged: false,
    isDynamicSectionAdded: false,
  },
  getters: {},
  mutations: {
    setIsDynamicSectionStatusChanged(state, status) {
      state.isDynamicSectionStatusChanged = status
    },

    setIsDynamicSectionAdded(state, status) {
      state.isDynamicSectionAdded = status
    },
  },
  actions: {
    /**
     * Reset Logout Time fields
     * @param commit
     * @private
     */
    _resetLogoutTimer({commit}) {
      commit('toggleSessionCheck', true, {root: true})
      commit('updateSessionTime', 0, {root: true})
    },

    /**
     * Get Dynamic Content Sections
     * @param context - Contains the commit, dispatch and state object
     * @returns {Promise<unknown>}
     * @private
     */
    _getDynamicContentSections({state, dispatch}) {
      return new Promise((resolve, reject) => {
        axios.get(`${state.base_url}/api/content/dynamic-content-section`)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Create Dynamic Content Section
     * @param context - Contains the commit, dispatch and state object
     * @param payload - Payload for the request
     * @returns {Promise<unknown>}
     * @private
     */
    _createDynamicSection({state, dispatch}, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`${state.base_url}/api/content/dynamic-content-section/store`, payload)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Update Dynamic Content Section
     * @param context - Contains the commit, dispatch and state object
     * @param payload - Payload for the request
     * @returns {Promise<unknown>}
     * @private
     */
    _updateDynamicSection({state, dispatch}, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`${state.base_url}/api/content/dynamic-content-section/update`, payload)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Get Listing Items of a dynamic content section
     * @param context - Contains the commit, dispatch and state object
     * @param params - Params for the request
     * @returns {Promise<unknown>}
     * @private
     */
    _getDynamicContentSectionListing({state, dispatch}, params) {
      return new Promise((resolve, reject) => {
        axios.get(`${state.base_url}/api/content/dynamic-content-section/section`, {params})
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Get Specific list item of dynamic content section
     * @param context - Contains the commit, dispatch and state object
     * @param params
     * @returns {Promise<unknown>}
     * @private
     */
    _getDynamicSectionListItem({state, dispatch}, params) {
      return new Promise((resolve, reject) => {
        axios.get(`${state.base_url}/api/content/dynamic-content-section/section/${params.dynamicSectionId}`)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Delete the selected dynamic section listing items
     * @param context - Contains the commit, dispatch and state object
     * @param params - Params for the request
     * @returns {Promise<unknown>}
     * @private
     */
    _deleteDynamicSectionListingItem({state, dispatch}, params) {
      return new Promise((resolve, reject) => {
        axios.delete(`${state.base_url}/api/content/dynamic-content-section/section`, {params})
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * update status of the selected dynamic section listing items
     * @param context - Contains the commit, dispatch and state object
     * @param payload - payload for the request
     * @returns {Promise<unknown>}
     * @private
     */
    _updateStatusDynamicSectionListingItem({state, dispatch}, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`${state.base_url}/api/content/dynamic-content-section/section/toggle-status`, payload)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Store the new list item for the specific dynamic section
     * @param context - Contains the commit, dispatch and state object
     * @param payload - formData and config for the API
     * @returns {Promise<unknown>}
     * @private
     */
    _storeDynamicSectionListingItem({state, dispatch}, {config, formData}) {
      return new Promise((resolve, reject) => {
        axios.post(`${state.base_url}/api/content/dynamic-content-section/section/store`, formData, {config})
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Store the new list item for the specific dynamic section
     * @param context - Contains the commit, dispatch and state object
     * @param payload - formData and config for the API
     * @returns {Promise<unknown>}
     * @private
     */
    _updateDynamicSectionListingItem({state, dispatch}, {config, formData}) {
      return new Promise((resolve, reject) => {
        axios.post(`${state.base_url}/api/content/dynamic-content-section/section/update`, formData, {config})
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Get All the dynamic section categories
     * @param context - Contains the commit, dispatch and state object
     * @param params - params for the API
     * @returns {Promise<unknown>}
     * @private
     */
    _getAllDynamicSectionCategories({state, dispatch}, params) {
      return new Promise((resolve, reject) => {
        axios.get(`${state.base_url}/api/content/dynamic-content-section/category/all`, {params})
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Get the requested dynamic content section
     * @param context - Contains the commit, dispatch and state object
     * @param params - params for the API
     * @returns {Promise<unknown>}
     * @private
     */
    _getRequestDynamicContentSection({state, dispatch}, {id}) {
      return new Promise((resolve, reject) => {
        axios.get(`${state.base_url}/api/content/dynamic-content-section/show/${id}`)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Get all sub categories base on category id
     * @param context - Contains the commit, dispatch and state object
     * @param params - params for the API
     * @returns {Promise<unknown>}
     * @private
     */
    _getDynamicSectionSubCategoriesByCategoryId({state, dispatch}, params) {
      return new Promise((resolve, reject) => {
        axios.get(`${state.base_url}/api/content/dynamic-content-section/sub-category/by-category-id`, {params})
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Fetch All the dynamic section categories
     * @param context - Contains the commit, dispatch and state object
     * @param params - params for the API
     * @returns {Promise<unknown>}
     * @private
     */
    _fetchAllDynamicSectionCategories({state, dispatch}, params) {
      return new Promise((resolve, reject) => {
        axios.get(`${state.base_url}/api/content/dynamic-content-section/category`, {params})
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Delete the selected dynamic section listing items
     * @param context - Contains the commit, dispatch and state object
     * @param params - Params for the request
     * @returns {Promise<unknown>}
     * @private
     */
    _deleteDynamicSectionCategoryItem({state, dispatch}, params) {
      return new Promise((resolve, reject) => {
        axios.delete(`${state.base_url}/api/content/dynamic-content-section/category`, {params})
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * update status of the selected dynamic section category items
     * @param context - Contains the commit, dispatch and state object
     * @param payload - payload for the request
     * @returns {Promise<unknown>}
     * @private
     */
    _updateStatusDynamicSectionCategoryItem({state, dispatch}, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`${state.base_url}/api/content/dynamic-content-section/category/toggle-status`, payload)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Store the new list item for the specific dynamic section category
     * @param context - Contains the commit, dispatch and state object
     * @param payload - formData and config for the API
     * @returns {Promise<unknown>}
     * @private
     */
    _storeDynamicSectionCategoryItem({state, dispatch}, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`${state.base_url}/api/content/dynamic-content-section/category/store`, payload)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Update the new list item for the specific dynamic section category
     * @param context - Contains the commit, dispatch and state object
     * @param payload - formData and config for the API
     * @returns {Promise<unknown>}
     * @private
     */
    _updateDynamicSectionCategoryItem({state, dispatch}, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`${state.base_url}/api/content/dynamic-content-section/category/update`, payload)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Get the requested dynamic section category
     * @param context - Contains the commit, dispatch and state object
     * @param params - formData and config for the API
     * @returns {Promise<unknown>}
     * @private
     */
    _showDynamicSectionCategoryItem({state, dispatch}, params) {
      return new Promise((resolve, reject) => {
        axios.get(`${state.base_url}/api/content/dynamic-content-section/category/show`, {params})
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Fetch All the dynamic section sub categories
     * @param context - Contains the commit, dispatch and state object
     * @param params - params for the API
     * @returns {Promise<unknown>}
     * @private
     */
    _fetchAllDynamicSectionSubCategories({state, dispatch}, params) {
      return new Promise((resolve, reject) => {
        axios.get(`${state.base_url}/api/content/dynamic-content-section/sub-category`, {params})
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * update status of the selected dynamic section sub category items
     * @param context - Contains the commit, dispatch and state object
     * @param payload - payload for the request
     * @returns {Promise<unknown>}
     * @private
     */
    _updateStatusDynamicSectionSubCategoryItem({state, dispatch}, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`${state.base_url}/api/content/dynamic-content-section/sub-category/toggle-status`, payload)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Delete the selected dynamic section sub category items
     * @param context - Contains the commit, dispatch and state object
     * @param params - Params for the request
     * @returns {Promise<unknown>}
     * @private
     */
    _deleteDynamicSectionSubCategoryItem({state, dispatch}, params) {
      return new Promise((resolve, reject) => {
        axios.delete(`${state.base_url}/api/content/dynamic-content-section/sub-category`, {params})
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Store the new list item for the specific dynamic section sub category
     * @param context - Contains the commit, dispatch and state object
     * @param payload - formData and config for the API
     * @returns {Promise<unknown>}
     * @private
     */
    _storeDynamicSectionSubCategoryItem({state, dispatch}, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`${state.base_url}/api/content/dynamic-content-section/sub-category/store`, payload)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Get the requested dynamic section sub category
     * @param context - Contains the commit, dispatch and state object
     * @param params - formData and config for the API
     * @returns {Promise<unknown>}
     * @private
     */
    _showDynamicSectionSubCategoryItem({state, dispatch}, params) {
      return new Promise((resolve, reject) => {
        axios.get(`${state.base_url}/api/content/dynamic-content-section/sub-category/show`, {params})
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Update the new list item for the specific dynamic section subcategory
     * @param context - Contains the commit, dispatch and state object
     * @param payload - formData and config for the API
     * @returns {Promise<unknown>}
     * @private
     */
    _updateDynamicSectionSubCategoryItem({state, dispatch}, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`${state.base_url}/api/content/dynamic-content-section/sub-category/update`, payload)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Update status for dynamic content section
     * @param context - Contains the commit, dispatch and state object
     * @param payload - formData and config for the API
     * @returns {Promise<unknown>}
     * @private
     */
    _updateStatusDynamicContentSection({state, dispatch}, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`${state.base_url}/api/content/dynamic-content-section/toggle-status`, payload)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Delete the selected dynamic section
     * @param context - Contains the commit, dispatch and state object
     * @param params - Params for the request
     * @returns {Promise<unknown>}
     * @private
     */
    _deleteDynamicContentSection({state, dispatch}, params) {
      return new Promise((resolve, reject) => {
        axios.delete(`${state.base_url}/api/content/dynamic-content-section`, {params})
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  }
};
