<template>
  <!-- don't add any other component here otherwise position sticky wont work -->
  <base-nav
    container-classes="container-fluid"
    class="navbar-top border-bottom navbar-expand bg_maroon stick-navbar"
    :class="{' navbar-dark': type === 'default'}"
  >
  <template #brand>
    <div class="sidenav-toggler-small" @click="minimizeSidebar">
            <svg id="Menu_Icon" data-name="Menu Icon" xmlns="http://www.w3.org/2000/svg" width="18" height="12" viewBox="0 0 18 12">
              <rect id="Rectangle" width="18" height="2" fill="#fff"/>
              <rect id="Rectangle-2" data-name="Rectangle" width="18" height="2" transform="translate(0 5)" fill="#fff"/>
              <rect id="Rectangle_" data-name="Rectangle " width="18" height="2" transform="translate(0 10)" fill="#fff"/>
          </svg>
    </div>
  </template>
    <!-- Navbar links -->
    <ul class="navbar-nav ml-auto ml-md-0 float-right ">
      <base-dropdown menu-on-right
                     class="nav-item"
                     tag="li"
                     title-tag="a"
                     title-classes="nav-link pr-0">
        <a href="#" class="nav-link pr-0" @click.prevent slot="title-container">
          <div class="media align-items-center">
                  <span class="avatar avatar-sm rounded-circle">
                    <img alt="Image placeholder" src="/img/Account.svg">
                  </span>
            <div class="media-body ml-2 d-none d-lg-block">
              <span class="mb-0 text-sm font-weight-bold">{{user.name}}</span>
              <span class="ml-2 fa fa-chevron-down" style="font-size: 12px"></span>
            </div>
          </div>
        </a>
          <a href="#!" class="dropdown-item" @click.prevent="changePassword">
            <span>Change Password</span>
          </a>
          <a href="#!" class="dropdown-item" @click.prevent="logout">
            <span>Logout</span>
          </a>
        <span class="custom-label">{{programCode}}</span>
      </base-dropdown>
    </ul>
  </base-nav>
</template>
<script>
import { BaseNav } from '@/components';

export default {
  components: {
    BaseNav
  },
  props: {
    type: {
      type: String,
      default: 'default', // default|light
      description: 'Look of the dashboard navbar. Default (Green) or light (gray)'
    }
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    programCode() {
      if(this.user.type != 'super_admin') return this.user.sub_user ? this.user.sub_user.programmer_code ? this.user.sub_user.programmer_code : '-----' : this.user.programmer_code ? this.user.programmer_code : '-----'
      return '';
    }
  },
  data() {
    return {
      user: {
        id:this.$route.params.id,
        name : null,
        program_code : '-----'
      },
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: '',
    };
  },
  methods: {    
    logout() {
      this.startLoading();
      this.$store.dispatch('_logout').then(response => {
        window.location.assign('/login');
        // this.$router.push({name: 'Login'});
      }).catch(err => {

      }).finally(() => {
        this.stopLoading();
      });
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },

    changePassword() {
      let name = 'ChangePassword';
      if(this.user.type == 'super_admin') name = 'admin.change_password';
      this.$router.push({name, params: {id: this.user.id}});
    },
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
      this.$root.$emit('isSideBarMinimize',this.$sidebar.isMinimized)
    }

  },
  mounted() {
    this.user = this.$store.getters.getAuthUser;
  }
};
</script>

<style scoped>
.custom-label {
  float: right;
  color: #525f7fb0;
  font-size: 0.875rem;
  font-weight: 400;
  padding: 0rem 1rem;
}

</style>
