import axios from "axios";

export default {
  namespaced: true,
  state: {
    base_url: process.env.VUE_APP_BASE_API_URL,
    tip: {
      id: "",
      user_id: "",
      name: "",
      user_ids: "",
      status: 1,
      created: "",
      modified: "",
    },
    sourceGetAll: "",
  },
  getters: {
    getTip(state) {
      return state.tip;
    },
  },
  actions: {
    /**
     * Reset Logout Time fields
     * @param commit
     * @private
     */
    _resetLogoutTimer({commit}) {
      commit("toggleSessionCheck", true, {root: true});
      commit("updateSessionTime", 0, {root: true});
    },

    /**
     * Get All Groups
     * @param context - Contains the commit and state object
     * @param params - Parameters for the API
     * @returns {Promise<unknown>}
     * @private
     */
    _get({state, dispatch}, params) {
      return new Promise((resolve, reject) => {
        if (state.sourceGetAll) {
          state.sourceGetAll.cancel();
        }
        const CancelToken = axios.CancelToken;
        state.sourceGetAll = CancelToken.source();
        axios.get(`${state.base_url}/api/admin/groups`, {
          params,
          cancelToken: state.sourceGetAll.token,
        })
          .then((response) => {
            dispatch("_resetLogoutTimer");
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    /**
     * Get Group By Id
     * @param context - Contains the commit and state object
     * @param id - Parameters for the API
     * @returns {Promise<unknown>}
     * @private
     */
    _show({state, dispatch}, id) {
      return new Promise((resolve, reject) => {
        axios.get(`${state.base_url}/api/admin/groups/${id}`)
          .then((response) => {
            dispatch("_resetLogoutTimer");
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    /**
     * Delete Group by id
     * @param context - Contains the commit and state object
     * @param id - Body for the API
     * @returns {Promise<unknown>}
     * @private
     */
    _delete({state, dispatch}, id) {
      return new Promise((resolve, reject) => {
        axios.post(`${state.base_url}/api/admin/groups/destroy`, {id})
          .then((response) => {
            dispatch("_resetLogoutTimer");
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    /**
     * Remove group member from group
     * @param context - Contains the commit and state object
     * @param fd - Body for the API
     * @returns {Promise<unknown>}
     * @private
     */
    _removeMember({state, dispatch}, fd) {
      return new Promise((resolve, reject) => {
        axios.post(`${state.base_url}/api/admin/groups/remove-member`, fd)
          .then((response) => {
            dispatch("_resetLogoutTimer");
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
  },
};
