import axios from "axios";

export default {
  namespaced: true,
  state: {
    base_url: process.env.VUE_APP_BASE_API_URL,
  },

  getters: {},

  mutations: {},

  actions: {
    _apiAfterCode({dispatch, commit, getters, rootGetters}) {
      commit('toggleSessionCheck', true, {root: true})
      commit('updateSessionTime', 0, {root: true})
    },

    _getMemberPortalList(context, payload=null) {
      return new Promise((resolve, reject) => {
        axios.get(context.state.base_url + '/api/content/member-portals', {params: payload}).then((response) => {
          context.dispatch('_apiAfterCode');
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      })
    },

    _storeMemberPortal: (context, payload = null) => {
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      };

      return new Promise((resolve, reject) => {
        axios.post(context.state.base_url + '/api/content/member-portals/store', payload,config).then((response) => {
          context.dispatch('_apiAfterCode');
          resolve(response);
        }).catch(error => {
          reject(error)
        });
      })
    },

    _updateMemberPortal: (context, payload=null) => {
      const config = {
        headers: { 'content-type': 'multipart/form-data' },
      };

      return new Promise((resolve, reject) => {
        axios.post(context.state.base_url + '/api/content/member-portals/update',payload,config).then((response) => {
          context.dispatch('_apiAfterCode');
          resolve(response);
        }).catch(error => {
          reject(error)
        });
      })
    },

    _showMemberPortal: (context, id=null) => {
      return new Promise((resolve, reject) => {
        axios.get(context.state.base_url + '/api/content/member-portals/'+id).then((response) => {
          context.dispatch('_apiAfterCode');
          resolve(response);
        }).catch(error => {
          reject(error)
        });
      })
    },

    _deleteMemberPortal: (context, payload=null) => {
      let config = {
        params: payload
      };

      return new Promise((resolve, reject) => {
        axios.delete(context.state.base_url + '/api/content/member-portals/delete', config).then((response) => {
          context.dispatch('_apiAfterCode');
          resolve(response);
        }).catch(error => {
          reject(error)
        });
      })
    },

    _toggleMemberPortalsStatus: (context, payload=null) => {
      return new Promise((resolve, reject) => {
        axios.post(context.state.base_url + '/api/content/member-portals/toggle-status', payload).then((response) => {
          context.dispatch('_apiAfterCode');
          resolve(response);
        }).catch(error => {
          reject(error)
        });
      })
    },

    _updateIndexes: (context, payload=null) => {
      return new Promise((resolve, reject) => {
        axios.post(context.state.base_url + '/api/content/member-portals/update-indexes', payload).then((response) => {
          context.dispatch('_apiAfterCode');
          resolve(response);
        }).catch(error => {
          reject(error)
        });
      })
    },
  }
};
