<template>
    <common-layout>
    <template #modals>
    <add-dynamic-content-section-modal
      :show-modal="dynamicSectionModal"
      @hideModal="dynamicSectionModal = false"
      @fetchDynamicSection="fetchDynamicSection"/>
    <notifications></notifications>
    </template>
    <template #sidebar>
    <side-bar>
      <template slot="links">

        <sidebar-item
          :link="{
            name: 'Dashboard',
            icon: 'ni ni-shop text-primary',
            path: '/dashboard'
          }"
        >
        </sidebar-item>

        <sidebar-item v-if="(userType == 'program_user' && programUserWithSidebarMenuList.manage_user == 1 && programUserWithSidebarMenuList.self_service != 1)
        ||  userType === 'sub_user'"
          :link="{
            name: 'Patients',
            icon: 'far fa-user text-primary',
          }"
        >
          <sidebar-item v-if="(userType == 'program_user' && programUserWithSidebarMenuList.manage_user == 1)
          ||  userType === 'sub_user'"
            :link="{ name: 'Manage Patients', path: '/manage-patients' }"
          >
          </sidebar-item>
          <sidebar-item v-if="(userType == 'program_user' && programUserWithSidebarMenuList.pipeline_status == 1)
           ||  userType === 'sub_user'"
            :link="{ name: 'Patient Pipeline', path: '/pipeline' }"
          ></sidebar-item>

        </sidebar-item>

        <sidebar-item v-if="(userType == 'program_user' && programUserWithSidebarMenuList.seminar_to_surgery == 1 && programUserWithSidebarMenuList.self_service != 1)
         ||  userType === 'sub_user'"
          :link="{
            name: 'Reports',
            icon: 'far fa-file-alt text-primary',
          }"
        >
          <sidebar-item v-if="(userType == 'program_user' && programUserWithSidebarMenuList.seminar_to_surgery == 1)
           ||  userType === 'sub_user'"
                        :link="{ name: 'Pipeline Reports', path: '/patient-pipeline-report' }"
          >
          </sidebar-item>

          <sidebar-item v-if="(userType == 'program_user' && programUserWithSidebarMenuList.seminar_to_surgery == 1)
           ||  userType === 'sub_user'"
                        :link="{ name: 'Checklist Reports', path: '/seminar2surgery' }"
          >
          </sidebar-item>

          <sidebar-item v-if="(userType == 'program_user' && programUserWithSidebarMenuList.seminar_to_surgery == 1)
           ||  userType === 'sub_user'"
                        :link="{ name: 'Surgery Reports'}">
            <sidebar-item v-if="(userType == 'program_user' && programUserWithSidebarMenuList.seminar_to_surgery == 1)
           ||  userType === 'sub_user'"
                          :link="{ name: 'Pre-Op Report', path: '/surgery-date-patients-report' }"
            >
            </sidebar-item>
            <sidebar-item v-if="(userType == 'program_user' && programUserWithSidebarMenuList.seminar_to_surgery == 1)
           ||  userType === 'sub_user'"
                          :link="{ name: 'Post-Op Report', path: '/post-op-patients-report' }"
            >
            </sidebar-item>
          </sidebar-item>

          <sidebar-item v-if="(userType == 'program_user' && programUserWithSidebarMenuList.seminar_to_surgery == 1)
           ||  userType === 'sub_user'"
                        :link="{ name: 'Never Used App', path: '/not-connected-patients-report' }"
          >
          </sidebar-item>

          <sidebar-item v-if="(userType == 'program_user' && programUserWithSidebarMenuList.seminar_to_surgery == 1)
           ||  userType === 'sub_user'"
                        :link="{ name: 'Support Group Attendance', path: '/events-rsvp-list' }"
          >
          </sidebar-item>

        </sidebar-item>

        <sidebar-item v-if="(userType == 'program_user' && programUserWithSidebarMenuList.alert_notification == 1 && programUserWithSidebarMenuList.self_service != 1)
         ||  userType === 'sub_user'"
          :link="{
            name: 'Alerts',
            icon: 'far fa-bell text-primary',
            path: '/alerts'
          }"
        >
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Messages',
            icon: 'far fa-comment-alt text-primary',
          }"
        >
          <sidebar-item
            :link="{ name: 'On Demand', path: '/on-demand-messages' }"
          >
          </sidebar-item>
          <sidebar-item
            :link="{ name: 'Scheduled Messages', path: '/scheduled-messages' }"
          >
          </sidebar-item>
          <sidebar-item
            :link="{ name:'Automatic Messages', path:'/automatic-messages' }"
          ></sidebar-item>
        </sidebar-item>

        <sidebar-item v-if="((userType == 'program_user' && (programUserWithSidebarMenuList.event == 1 || programUserWithSidebarMenuList.self_service == 1))||  userType === 'sub_user')&& checkUserMenuStatus('Calendar')"
          :link="{
            name: 'Event Calendar',
            icon: 'far fa-calendar-minus text-primary',
            path: '/event-calender'
          }"
        ></sidebar-item>

        <sidebar-item
          :link="{
            name: 'Content',
            icon: 'far fa-file-alt text-primary',
          }"
        >
          <span v-show="userType == 'sub_user'"
                role="button" class="nav-link dynamic-section-button"
                @click="showAddDynamicSectionModal">
            <img src="/img/plus.svg" width="20" height="20" alt="">Add New Section
          </span>
<!--          //for Henry Ford Weight Management start-->
          <sidebar-item v-if="authUser.program_id == henryFordId"
            :link="{
            name: 'Weight Management',
            icon: 'far fa-file-alt text-primary',
          }"
          >
          <sidebar-item v-if="(userType == 'program_user' && (programUserWithSidebarMenuList.contact == 1 || programUserWithSidebarMenuList.self_service == 1))
          ||  userType === 'sub_user'"
            :link="{ name: 'Contact Us', path: '/contact-us?weightLossType=1', key:'abc'}"
          >
          </sidebar-item>

            <sidebar-item  v-if="(userType == 'program_user' && (programUserWithSidebarMenuList.non_surgeries_wight == 1 || programUserWithSidebarMenuList.self_service == 1))
            ||  userType === 'sub_user'"
                           :link="{ name: 'Non Surgical Weight Loss', path: '/non-surgical-weight-loss' }"
            >
            </sidebar-item>

          </sidebar-item>
<!--          //for Henry Ford Weight Management End-->



<!--          //for Henry Ford Weight Loss surgery start-->
          <sidebar-item
            v-if="authUser.program_id == henryFordId"
            :link="{
            name: 'Weight Loss Surgery',
            icon: 'far fa-file-alt text-primary',
          }"
          >
            <content-menu-items :dynamic-content-sections-list="dynamicContentSectionsList" ref="contentMenuItemsRef"
                                :programUserWithSidebarMenuList="programUserWithSidebarMenuList"
                                :userType="userType" :henryFordId="henryFordId" :programId="authUser.program_id">
            </content-menu-items>

          </sidebar-item>
<!--          //for Henry Ford Weight Loss surgery End-->


          <content-menu-items :dynamic-content-sections-list="dynamicContentSectionsList" ref="contentMenuItemsRef"
                              v-if="authUser.program_id != henryFordId"
                              :programUserWithSidebarMenuList="$store.state.programUserWithSidebarMenuList"
                              :sub-menu-managers="$store.state.fromSubMenuManagers"
                              :userType="userType" :henryFordId="henryFordId" :programId="authUser.program_id">
          </content-menu-items>

        </sidebar-item>

        <sidebar-item v-if="userType == 'sub_user' || programUserWithSidebarMenuList.self_service != 1"
          :link="{
            name: 'Templates',
            icon: 'far fa-sticky-note text-primary'
          }"
        >
          <sidebar-item
            :link="{ name: 'Checklist Templates', path: '/checklist-templates' }"
          >
          </sidebar-item>
          <sidebar-item
            :link="{ name: 'Message Templates', path: '/message-templates' }"
          ></sidebar-item>
        </sidebar-item>
        <sidebar-item
          v-if="(userType == 'program_user' && programUserWithSidebarMenuList.manage_user == 1 && programUserWithSidebarMenuList.self_service != 1)
          ||  userType === 'sub_user'"
          :link="{
            name: 'Patient Tags',
            icon: 'fas fa-list text-primary',
            path: '/user-category'
          }"
        ></sidebar-item>
        <sidebar-item
          v-if="userType === 'sub_user'"
          :link="{
            name: 'Manage Menu',
            icon: 'fas fa-list text-primary',
            path: '/manage-menu'
          }"
        ></sidebar-item>

        <sidebar-item
          v-if="userType === 'program_user'"
          :link="{ name: 'Help', icon: 'far fa-question-circle text-primary' }">
          <sidebar-item
            v-if="userType === 'program_user'"
            :link="{ name: 'Baritastic Support', path: '/support-messages/baritastic-support/store' }"
          />
          <sidebar-item
            v-if="userType === 'program_user'"
            :link="{ name: 'Support Group Assistance', path: '/support-messages/support-groups/store' }"
          />
          <sidebar-item
            v-if="userType === 'program_user'"
            :link="{ name: 'Request Vitamin Samples', path: '/support-messages/vitamin-supplements/store' }"
          />
        </sidebar-item>
      </template>
    </side-bar>
    </template>
    <template #navbar>
      <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
    </template>
  </common-layout>
</template>
<script>
  /* eslint-disable no-new */
  import ContentMenuItems from "@/views/Components/Sidebar/ContentMenuItems";
  import CommonLayout from "@/views/Layout/CommonLayout/CommonLayout";
  import DashboardNavbar from './DashboardNavbar.vue';
  import moment from "moment";
  import AddDynamicContentSectionModal
    from "@/views/Pages/ContentSection/DynamicContentSection/AddDynamicContentSectionModal";

  export default {
    components: {
      ContentMenuItems,
      DashboardNavbar,
      AddDynamicContentSectionModal,
      CommonLayout
    },
    data () {
      return {
        henryFordId: process.env.VUE_APP_HENRY_FORD_ID,
        programUserWithSidebarMenuList : {},
        fromSubMenuManagers : [],
        userType : {},
        authUser : {},
        dynamicSectionModal: false,
        dynamicContentSectionsList: [],
      }
    },
    methods: {
      getSidebarMenuList(){
        let vm = this;
        vm.$store.dispatch('_getSidebarMenuList')
          .then((response) => {
            // console.log(response.data.data.fromSubMenuManagers);
            vm.programUserWithSidebarMenuList = response.data.data.fromPUsTable;
            vm.fromSubMenuManagers = response.data.data.fromSubMenuManagers;
            vm.userType = response.data.data.authUser.type;
            vm.authUser = response.data.data.authUser;
            vm.dynamicContentSectionsList = response.data.data.dynamicContentSections;
            vm.setIntercom();
          }).catch(error => {
          console.error(error.response)
        }).finally(() => {
        });
      },
      setIntercom(){
        let authuser = this.$store.getters.getAuthUser;
        if (authuser){
          window.Intercom('boot',{
            app_id: "jedq1yf9",
            name: authuser.name, // Full name
            email: authuser.email, // Email address
            created_at: moment(authuser.created).unix() // Signup date as a Unix timestamp
          });
        }
      },
      checkUserMenuStatus(name){
        let status = false;
        let sidebarMenuListFromSubMenuManagers = this.$store.getters.getSidebarMenuListFromSubMenuManagers;
        if(sidebarMenuListFromSubMenuManagers){
          sidebarMenuListFromSubMenuManagers.filter(function(menuItem){
            if (menuItem.sub_menu.title == name && menuItem.status == 1) {
              status =  true;
            }
          });
        }
        return status;
      },

      /**
       * Show add dynamic section modal
       */
      showAddDynamicSectionModal() {
        this.dynamicSectionModal = true
      },

      /**
       * Fetch the dynamic sections
       */
      fetchDynamicSection() {
        this.$refs.contentMenuItemsRef.getDynamicContentSections()
      }
    },
    mounted() {
      this.getSidebarMenuList();
    },
  };
</script>
<style lang="scss">
.dynamic-section-button {
  img {
    margin-right: 8px;
    margin-left: -3px;
  }

  background-color: #f6f9fc;
}
</style>
