import axios from "axios";

export default {
  namespaced: true,
  state: {
    base_url: process.env.VUE_APP_BASE_API_URL,
    quote: {
      user_id: 48,
      description: '',
      date_quote: '',
      status: 1
    }
  },
  getters: {
    getQuote(state) {
      return state.quote
    }
  },
  actions: {
    /**
     * Reset Logout Time fields
     * @param commit
     * @private
     */
    _resetLogoutTimer({commit}) {
      commit('toggleSessionCheck', true, {root: true})
      commit('updateSessionTime', 0, {root: true})
    },

    /**
     * Get All Quotes
     * @param context - Contains the commit and state object
     * @param params - Parameters for the API
     * @returns {Promise<unknown>}
     */
    _getAllQuotes({state, dispatch}, params) {
      return new Promise((resolve, reject) => {
        axios.get(`${state.base_url}/api/admin/quotes?page=${params.page}`, {params})
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    /**
     * Get Quote by id
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */
    _getQuoteById({state, dispatch}, {id}) {
      return new Promise((resolve, reject) => {
        axios.get(`${state.base_url}/api/admin/quotes/${id}`)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Store Quote
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */
    _storeQuote({state, dispatch}, {formData}) {
      return new Promise((resolve, reject) => {
        axios.post(`${state.base_url}/api/admin/quotes/store`, formData)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Update Quote
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */
    _updateQuote({state, dispatch}, {formData}) {
      return new Promise((resolve, reject) => {
        axios.post(`${state.base_url}/api/admin/quotes/update`, formData)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Delete Bari Resource(s)
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * * @returns {Promise<unknown>}
     * @private
     */
    _deleteQuote({state, dispatch}, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`${state.base_url}/api/admin/quotes/delete`, payload)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          }).catch(error => {
          reject(error)
        })
      })
    },

    /**
     * Update Quote(s) Status
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * * @returns {Promise<unknown>}
     * @private
     */
    _updateStatusQuote({state, dispatch}, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`${state.base_url}/api/admin/quotes/toggle-status`, payload)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          }).catch(error => {
          reject(error)
        })
      })
    },
  }
};
