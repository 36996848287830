import axios from "axios";

export default {
  namespaced: true,
  state: {
    base_url: process.env.VUE_APP_BASE_API_URL,
    tip: {
      id: "",
      user_id: "",
      description: "",
      created: "",
      modified: "",
      status: 1,
    },
    sourceGetAll: "",
  },
  getters: {
    getTip(state) {
      return state.tip;
    },
  },
  actions: {
    /**
     * Reset Logout Time fields
     * @param commit
     * @private
     */
    _resetLogoutTimer({ commit }) {
      commit("toggleSessionCheck", true, { root: true });
      commit("updateSessionTime", 0, { root: true });
    },

    /**
     * Get All Tips
     * @param context - Contains the commit and state object
     * @param params - Parameters for the API
     * @returns {Promise<unknown>}
     * @private
     */
    _get({ state, dispatch }, params) {
      return new Promise((resolve, reject) => {
        if (state.sourceGetAll) {
          state.sourceGetAll.cancel();
        }
        const CancelToken = axios.CancelToken;
        state.sourceGetAll = CancelToken.source();
        axios
          .get(`${state.base_url}/api/admin/tips`, {
            params,
            cancelToken: state.sourceGetAll.token,
          })
          .then((response) => {
            dispatch("_resetLogoutTimer");
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    /**
     * Store new tip
     * @param context - Contains the commit and state object
     * @param fd - Body for the API
     * @returns {Promise<unknown>}
     * @private
     */
    _store({ state, dispatch }, fd) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${state.base_url}/api/admin/tips`, fd)
          .then((response) => {
            dispatch("_resetLogoutTimer");
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    /**
     * Update Tip by id
     * @param context - Contains the commit and state object
     * @param {fd, id} - Body for the API
     * @returns {Promise<unknown>}
     * @private
     */
    _update({ state, dispatch }, { fd, id }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${state.base_url}/api/admin/tips/${id}`, fd)
          .then((response) => {
            dispatch("_resetLogoutTimer");
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    /**
     * Delete Tip by id
     * @param context - Contains the commit and state object
     * @param id - Body for the API
     * @returns {Promise<unknown>}
     * @private
     */
    _delete({ state, dispatch }, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${state.base_url}/api/admin/tips/${id}`)
          .then((response) => {
            dispatch("_resetLogoutTimer");
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    /**
     * Toggle status for multiple id
     * @param context - Contains the commit and state object
     * @param fd - Body for the API
     * @returns {Promise<unknown>}
     * @private
     */
    _toggleStatus({ state, dispatch }, fd) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${state.base_url}/api/admin/tips/toggle-status`, fd)
          .then((response) => {
            dispatch("_resetLogoutTimer");
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
  },
};
