<template>
  <div class="wrapper">
    <slot name="modals"></slot>
    <slot name="sidebar"></slot>
    <div class="main-content">
      <div id="loader"></div>
      <slot name="navbar"></slot>
      <common-modals/>

      <div style="" @click="$sidebar.displaySidebar(false)">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
      </div>

    </div>
  </div>
</template>
<script>
  /* eslint-disable no-new */
  import PerfectScrollbar from 'perfect-scrollbar';
  import 'perfect-scrollbar/css/perfect-scrollbar.css';
  import {FadeTransition} from 'vue2-transitions';
  import CommonModals from './CommonModals.vue';
  function hasElement(className) {
    return document.getElementsByClassName(className).length > 0;
  }

  function initScrollbar(className) {
    if (hasElement(className)) {
      new PerfectScrollbar(`.${className}`);
    } else {
      // try to init it later in case this component is loaded async
      setTimeout(() => {
        initScrollbar(className);
      }, 100);
    }
  }

  export default {
    components: {
      FadeTransition,
      CommonModals
    },
    data () {
      return {
      }
    },
    methods: {
      initScrollbar() {
        let isWindows = navigator.platform.startsWith('Win');
        if (isWindows) {
          initScrollbar('sidenav');
        }
      },
      updateSideBarState({detail}) {
        localStorage.sideBarState = !detail
      },
    },
    mounted() {
      this.initScrollbar();
      if (localStorage.sideBarState === 'false') {
        this.$sidebar.minimize()
        localStorage.sideBarState = !this.$sidebar.isMinimized
      }
      window.addEventListener('toggleUsed', this.updateSideBarState)
    },
    beforeDestroy() {
      window.removeEventListener("toggleUsed", this.updateSideBarState, false)
    },
    computed: {

    }
  };
</script>
<style lang="scss">

</style>
