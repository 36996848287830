<template>
  <div v-if="isUserAllowedAndNoEventsExist && checkUserMenuStatus('Calendar')"
       class="no-event-message-modal position-fixed bg-white">
    <i class="fa fa-times-circle pointer float-right" @click="hideModal"/>
    <div class="d-flex">
      <i class="fa fa-bell no-event-bell-icon"></i>
      <p class="pt-2 m-0 font-size-16px font-weight-500 lh-140">
        You have 0 events in your calendar. Please make sure you add new events to
        your calendar or send the event details to your account manager to have them added.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "NoEventsMessageModal",
  methods: {
    hideModal() {
      this.$store.commit('toggleNoEventsMessageModal', false)
    },

    checkUserMenuStatus(name) {
      let status = false
      let sidebarMenuListFromSubMenuManagers = this.$store.getters.getSidebarMenuListFromSubMenuManagers
      if (sidebarMenuListFromSubMenuManagers) {
        sidebarMenuListFromSubMenuManagers.filter(function (menuItem) {
          if (menuItem.sub_menu.title == name && menuItem.status == 1) {
            status = true
          }
        })
      }
      return status
    },
  },
  computed: {
    isUserAllowedAndNoEventsExist() {
      return this.$store.state.modals.noEventsMessageModal && this.$store.state.authUser ?
        ((this.$store.state.authUser.type == 'sub_user' ||
          (this.$store.state.authUser.type == 'program_user' &&
            (this.$store.state.programUserWithSidebarMenuList.event == 1 ||
              this.$store.state.programUserWithSidebarMenuList.self_service == 1))))
        : false
    }
  }
}
</script>

<style>
.no-event-message-modal {
  z-index: 1050;
  padding: 12px 20px 15px 20px;
  margin-top: -65px;
  left: 50%;
  transform: translate(-50%, 0);
  max-width: 730px;
  box-shadow: 0 3px 30px #0000001A;
  border-radius: 6px;
}

.no-event-bell-icon {
  font-size: 35px;
  margin-right: 20px;
  margin-top: 13px;
}
</style>
