import axios from "axios";

export default {
  namespaced: true,
  state: {
    base_url: process.env.VUE_APP_BASE_API_URL,
    programUser: {
      parent_id: 48,
      sub_user_id: '',
      matrix_system: 1,
      name: '',
      email: '',
      username: '',
      password: '',
      password1: '',
      last_changed: '',
      last_changed_status: 0,
      self_service: 0,
      all_check: 0,
      default_stage: '',
      contact: 0,
      manage_user: 0,
      event: 0,
      instruction: 0,
      diet: 0,
      s_notifications: 0,
      c_notifications: 0,
      i_category: 0,
      d_category: 0,
      faq: 0,
      our_team: 0,
      online_seminar: 0,
      our_website: 0,
      patient_portal: 0,
      about_surgery: 0,
      videos: 0,
      vitamin_supplement: 0,
      support_group: 0,
      program_logo: 0,
      alert_notification: 0,
      check_tempalate: 0,
      activation_key: 0,
      deactivation_date: '',
      deactivation_status: 0,
      our_blog: 0,
      shop_url: 0,
      join_us_facebook: 0,
      support_services: 0,
      seminar_to_surgery: 0,
      after_before: 0,
      pipeline_status: 0,
      non_surgeries_wight: 0,
      status: 0,
      security_status: 0,
      Newsletter: 0,
      time_out: '',
      retries: 0,
      first_pass: 1,
      reset_key: '',
      is_password_changed: 0,
      terms_and_conditions_check: 0,
      role: '',
      default_category1_id: '',
      ip_address: '',
      ip_modified_at: '',
      administrative_rights: 0,
      send_email_check: 0,
    },
    sourceGetAll: '',
  },
  getters: {
    getProgramUser(state) {
      return state.programUser
    },
  },
  actions: {
    /**
     * Reset Logout Time fields
     * @param commit
     * @private
     */
    _resetLogoutTimer({commit}) {
      commit('toggleSessionCheck', true, {root: true})
      commit('updateSessionTime', 0, {root: true})
    },

    /**
     * Get Program's Listing
     * @param context - Contains the commit and state object
     * @param params - Parameters for the API
     * @returns {Promise<unknown>}
     * @private
     */
    _getProgramUsers({state, dispatch}, params) {
      return new Promise((resolve, reject) => {
        if (state.sourceGetAll) {
          state.sourceGetAll.cancel()
        }
        const CancelToken = axios.CancelToken;
        state.sourceGetAll = CancelToken.source();
        axios.get(`${state.base_url}/api/admin/program-users`, {params, cancelToken: state.sourceGetAll.token})
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Get Requested Program User
     * @param context - Contains the commit and state object
     * @param params - Parameters for the API
     * @returns {Promise<unknown>}
     * @private
     */
    _getRequestedProgramUser({state, dispatch}, params) {
      return new Promise((resolve, reject) => {
        axios.get(`${state.base_url}/api/admin/program-users/${params.id}`)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Update Program User Password
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */
    _updateProgramUserPassword({state, dispatch}, {payload}) {
      return new Promise((resolve, reject) => {
        axios.post(`${state.base_url}/api/admin/program-users/update-password`, payload)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Reset Program User Security
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */
    _resetProgramUserSecurityAnswers({state, dispatch}, {payload}) {
      return new Promise((resolve, reject) => {
        axios.post(`${state.base_url}/api/admin/program-users/reset-security`, payload)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Resend Program User Dashboard Invite
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */
    _resendDashboardInviteProgramUser({state, dispatch}, {payload}) {
      return new Promise((resolve, reject) => {
        axios.post(`${state.base_url}/api/admin/program-users/resend-invite`, payload)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Delete Program User(s)
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * * @returns {Promise<unknown>}
     * @private
     */
    _deleteProgramUser({state, dispatch}, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`${state.base_url}/api/admin/program-users/delete`, payload)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          }).catch(error => {
          reject(error)
        })
      })
    },

    /**
     * Update Program User(s) status
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * * @returns {Promise<unknown>}
     * @private
     */
    _updateStatusProgramUser({state, dispatch}, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`${state.base_url}/api/admin/program-users/toggle-status`, payload)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          }).catch(error => {
          reject(error)
        })
      })
    },

    /**
     * Store Baritastic Program
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */
    _storeProgramUser({state, dispatch}, {formData}) {
      return new Promise((resolve, reject) => {
        axios.post(`${state.base_url}/api/admin/program-users/store`, formData)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    /**
     * Update Baritastic Program
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */
    _updateProgramUser({state, dispatch}, {formData}) {
      return new Promise((resolve, reject) => {
        axios.post(`${state.base_url}/api/admin/program-users/update`, formData)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Export Program User Listing
     * @param context - Contains the commit and state object
     * @param params - Params for the API
     * @returns {Promise<unknown>}
     */
    _exportProgramUsers({state, dispatch}, params) {
      return new Promise((resolve, reject) => {
        axios.get(`${state.base_url}/api/admin/program-users/export`, {params, responseType: "blob"})
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  }
};
