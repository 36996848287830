import axios from "axios";

export default {
  namespaced: true,
  state: {
    base_url: process.env.VUE_APP_BASE_API_URL,
    supportMessage: {
      sub_user_id: '',
      type: 'baritastic_support',
      message: '',
    }
  },
  getters: {
    getSupportMessage(state) {
      return state.supportMessage
    },
  },
  actions: {
    /**
     * Reset Logout Time fields
     * @param commit
     * @private
     */
    _resetLogoutTimer({commit}) {
      commit('toggleSessionCheck', true, {root: true})
      commit('updateSessionTime', 0, {root: true})
    },

    /**
     * Get All Support Email
     * @param context - Contains the commit and state object
     * @returns {Promise<unknown>}
     * @private
     */
    _getAllSupportEmails({state, dispatch}) {
      return new Promise((resolve, reject) => {
        axios.get(`${state.base_url}/api/admin/support-email`)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Update Support Emails
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */
    _updateSupportEmails({state, dispatch}, {formData}) {
      return new Promise((resolve, reject) => {
        axios.post(`${state.base_url}/api/admin/support-email/update`, formData)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    // /**
    //  * Get Support Messages
    //  * @param context - Contains the commit and state object
    //  * @param payload - Payload for the API
    //  * @returns {Promise<unknown>}
    //  * @private
    //  */
    // _getSupportMessages({state, dispatch}, {params}) {
    //   return new Promise((resolve, reject) => {
    //     axios.get(`${state.base_url}/api/support-message`, {params})
    //       .then(response => {
    //         dispatch('_resetLogoutTimer')
    //         resolve(response)
    //       })
    //       .catch(error => reject(error))
    //   })
    // },

    /**
     * Store Support Message
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */
    _storeSupportMessage({state, dispatch}, {formData}) {
      return new Promise((resolve, reject) => {
        axios.post(`${state.base_url}/api/support-message/store`, formData)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  }
}
