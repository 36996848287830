<template>
    <div class="d-flex align-items-center px-2">
        <div class="current-pagination">
            {{ pageInfo }}
        </div>
        <div class="pagination-separator-custom ml-3 mr-3">
        </div>
        <div class="pagination-changer mt--1">
            Items per page
            <el-select class="select-primary pagination-select paginated-table-select ml-2" @change="changedPagination"
                v-model="paginationSelected" size="mini">
                <el-option class="select-primary" v-for="(page, index) in paginationArr" :key="index" :label="page"
                    :value="page != 'All' ? page: ''">
                </el-option>
            </el-select>
        </div>
    </div>
</template>
<script>
export default {
    name: "base-item-per-page",
    props: {
        currentPage: {
            type: Number,
            default: 1
        },
        lastPage: {
            type: Number,
            default: 1
        },
        itemPerPage:{
            type: [String,Number],
            default: '10'
        }
    },
    mounted(){
        this.paginationSelected = this.itemPerPage.toString();
    },
    data() {
        return {
            paginationSelected: '',
            paginationArr: ['5', '10', '15', '20','50','100','All'],
        }
    },
    methods: {
        changedPagination(value) {
            this.$emit('changedPagination', value);
        },
    },
    computed:{
        pageInfo(){
            return `Page ${this.currentPage} of ${this.lastPage}`
        }
    }
};
</script>
<style>
.pagination-separator-custom{
    border-right: 1px solid rgba(0, 0, 0, 0.39);
    height:  75%;
}
</style>
  