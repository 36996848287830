import axios from "axios";

export default {
  namespaced: true,
  state: {
    base_url: process.env.VUE_APP_BASE_API_URL,
    sourceGetAll: null,
    sourceReorder: null,
    sourceGetAllCategories: null
  },
  getters: {},
  actions: {
    /**
     * Reset Logout Time fields
     * @param commit
     * @private
     */
    _resetLogoutTimer({ commit }) {
      commit("toggleSessionCheck", true, { root: true });
      commit("updateSessionTime", 0, { root: true });
    },
    /**
     * Get All How To Use Apps
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */
    
    _getAllHowToUseApp({ state, dispatch }, { params }) {
      return new Promise((resolve, reject) => {
        if(state.sourceGetAll){
          state.sourceGetAll.cancel()
        }
        const CancelToken = axios.CancelToken;
        state.sourceGetAll = CancelToken.source();
        axios
          .get(`${state.base_url}/api/admin/how-to-use-app`, { params,  cancelToken: state.sourceGetAll.token })
          .then((response) => {
            dispatch("_resetLogoutTimer");
            resolve(response);
          })
          .catch((error) => {
            if (axios.isCancel(error)) {
              // Request was canceled
              resolve({});
            } else {
              // Handle other errors
              reject(error)
            }
          });
      });
    },
    
    /**
     * Get How To Use App By Id
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */
    _getHowToUseAppById({ state, dispatch }, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${state.base_url}/api/admin/how-to-use-app/show/${id}`)
          .then((response) => {
            dispatch("_resetLogoutTimer");
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    
    /**
     * Store How To Use App
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */

    _storeHowToUseApp({ state, dispatch }, { fd }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${state.base_url}/api/admin/how-to-use-app/store`, fd)
          .then((response) => {
            dispatch("_resetLogoutTimer");
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    /**
     * Update How To Use App
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */

    _updateHowToUseApp({ state, dispatch }, { fd,id }) {
        return new Promise((resolve, reject) => {
          axios
            .put(`${state.base_url}/api/admin/how-to-use-app/update/${id}`, fd)
            .then((response) => {
              dispatch("_resetLogoutTimer");
              resolve(response);
            })
            .catch((error) => reject(error));
        });
    },
    /**
     * delete How To Use App By Id
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */
    _deleteHowToUseAppById({ state, dispatch }, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${state.base_url}/api/admin/how-to-use-app/destroy/${id}`)
          .then((response) => {
            dispatch("_resetLogoutTimer");
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    /**
     * delete multiple How To Use App by IDs
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */
    _deleteMultipleHowToUseApp({ state, dispatch }, { ids }) {
        return new Promise((resolve, reject) => {
          axios
            .post(`${state.base_url}/api/admin/how-to-use-app/destroy-multiple`,{ids})
            .then((response) => {
              dispatch("_resetLogoutTimer");
              resolve(response);
            })
            .catch((error) => reject(error));
        });
    },
    /**
     * Toggle Status of multiple How To Use App by IDs
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */
    _toggleStatusMultipleHowToUseApp({ state, dispatch }, { fd }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${state.base_url}/api/admin/how-to-use-app/toggle-status-multiple`,fd)
          .then((response) => {
            dispatch("_resetLogoutTimer");
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    /**
     * Toggle Status of multiple How To Use App by IDs
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */
    _reorderHowToUseApp({ state, dispatch }, { fd }) {
      return new Promise((resolve, reject) => {
        if(state.sourceReorder){
          state.sourceReorder.cancel()
        }
        const CancelToken = axios.CancelToken;
        state.sourceReorder = CancelToken.source();
        axios
          .post(`${state.base_url}/api/admin/how-to-use-app/reorder`,fd,{ cancelToken: state.sourceReorder.token })
          .then((response) => {
            dispatch("_resetLogoutTimer");
            resolve(response);
          })
          .catch((error) => {
            if (axios.isCancel(error)) {
              // Request was canceled
              resolve({});
            } else {
              // Handle other errors
              reject(error)
            }
          });
      });
    },

    /**
     * Get Categories of How To Use Apps for Selection
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */
    _getHowToUseAppCategorySelect({ state, dispatch }, { params }) {
      return new Promise((resolve, reject) => {
        if(state.sourceGetAllCategories){
          state.sourceGetAllCategories.cancel()
        }
        const CancelToken = axios.CancelToken;
        state.sourceGetAllCategories = CancelToken.source();
        axios
          .get(`${state.base_url}/api/admin/how-to-use-app/category/select`, { params,  cancelToken: state.sourceGetAllCategories.token })
          .then((response) => {
            dispatch("_resetLogoutTimer");
            resolve(response);
          })
          .catch((error) => {
            if (axios.isCancel(error)) {
              // Request was canceled
              resolve({});
            } else {
              // Handle other errors
              reject(error)
            }
          });
      });
    },
    /**
     * Get All Categories of How To Use Apps with paginate
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */
    _getAllHowToUseAppCategory({ state, dispatch }, { params }) {
      return new Promise((resolve, reject) => {
        if(state.sourceGetAllCategories){
          state.sourceGetAllCategories.cancel()
        }
        const CancelToken = axios.CancelToken;
        state.sourceGetAllCategories = CancelToken.source();
        axios
          .get(`${state.base_url}/api/admin/how-to-use-app/category`, { params,  cancelToken: state.sourceGetAllCategories.token })
          .then((response) => {
            dispatch("_resetLogoutTimer");
            resolve(response);
          })
          .catch((error) => {
            if (axios.isCancel(error)) {
              // Request was canceled
              resolve({});
            } else {
              // Handle other errors
              reject(error)
            }
          });
      });
    },
    /**
     * Store How To Use App Category
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */

    _storeHowToUseAppCategory({ state, dispatch }, { fd }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${state.base_url}/api/admin/how-to-use-app/category/store`, fd)
          .then((response) => {
            dispatch("_resetLogoutTimer");
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    /**
     * Update How To Use App Category
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */

    _updateHowToUseAppCategory({ state, dispatch }, { fd,id }) {
        return new Promise((resolve, reject) => {
          axios
            .put(`${state.base_url}/api/admin/how-to-use-app/category/update/${id}`, fd)
            .then((response) => {
              dispatch("_resetLogoutTimer");
              resolve(response);
            })
            .catch((error) => reject(error));
        });
    },
    
    /**
     * delete How To Use App By Id
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */
    _deleteHowToUseAppCategoryById({ state, dispatch }, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${state.base_url}/api/admin/how-to-use-app/category/destroy/${id}`)
          .then((response) => {
            dispatch("_resetLogoutTimer");
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    /**
     * delete multiple How To Use App Category by IDs
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */
    _deleteMultipleHowToUseAppCategory({ state, dispatch }, { ids }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${state.base_url}/api/admin/how-to-use-app/category/destroy-multiple`,{ids})
          .then((response) => {
            dispatch("_resetLogoutTimer");
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    /**
     * Toggle Status of multiple How To Use App Category by IDs
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */
    _toggleStatusMultipleHowToUseAppCategory({ state, dispatch }, { fd }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${state.base_url}/api/admin/how-to-use-app/category/toggle-status-multiple`,fd)
          .then((response) => {
            dispatch("_resetLogoutTimer");
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
  },
};