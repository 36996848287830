export default {
  methods: {
    /**
     * Generate Error message as HTML
     * @param errors
     * @returns {string}
     */
    generateErrorMessage(errors) {
      let message = '<ul>'
      for (const arr in errors) {
        for (const item in errors[arr]) {
          message += '<li>' + errors[arr][item] + '</li>'
          message = message.replace(' new', '')
        }
      }
      message += '</ul>'
      return message
    },
      /**
     * Generate Error message for validation observer form
     * @param errors
     * @param title
     * @param formValidation
     * @returns @void
     */
    showErrorOnForm(error,title,formValidation){
      let errors = error.response.data?.errors || {};
      let message = error?.response?.data?.message || "Error";
      if (Object.keys(errors).length) {
        formValidation.setErrors(errors);
      } else {
        this.$notify.error({
          title,
          dangerouslyUseHTMLString: true,
          message: message,
        });
      }
    }
  }
}
