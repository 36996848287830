import AuthLayout from '@/views/Pages/AuthLayout.vue';

const Home = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Home.vue');
const Register = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Register.vue');
const Login = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Login.vue');
const SSO = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/SSO.vue');
const AnswerSecurityQuestion = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/SecurityQuestion/AnswerSecurityQuestion.vue');
const SetupSecurityQuestion = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/SecurityQuestion/SetupSecurityQuestion.vue');
const ForgotPassword = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/ForgotPassword.vue');
const ResetPassword = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/ResetPassword.vue');
const Pricing = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Pricing.vue');
const Lock = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Lock.vue');
const TermsAndCondition = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/TermsAndCondition.vue');
import NotFound from '@/views/GeneralViews/NotFoundPage.vue';

import authHelper from "../util/authHelper";

const authRoutes = [
  {
    path: '/terms-and-conditions',
    name: 'TermsAndCondition',
    component: TermsAndCondition
  },
  {
    path: '/',
    component: AuthLayout,
    name: 'Authentication',
    children: [
      {
        path: '/home',
        name: 'Home',
        component: Home,
        meta: {
          noBodyBackground: true
        }
      },
      {
        path: '/login',
        name: 'Login',
        component: Login,
        beforeEnter: (to, from, next) => authenticate(to, from, next)
      },
      {
        path: '/sso',
        name: 'SSO',
        component: SSO
      },
      {
        path: '/answer-question',
        name: 'AnswerQuestion',
        component: AnswerSecurityQuestion,
        beforeEnter: (to, from, next) => protectSecurityRoute(to, from, next)
      },
      {
        path: '/setup-question',
        name: 'SetupQuestion',
        component: SetupSecurityQuestion,
        beforeEnter: (to, from, next) => protectSecurityRoute(to, from, next)
      },
      {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: ForgotPassword
      },
      {
        path: '/reset-password',
        name: 'ResetPassword',
        component: ResetPassword
      },
      {
        path: '/register',
        name: 'Register',
        component: Register
      },

      {path: '*', component: NotFound}
    ],
    beforeEnter: (to, from, next) => authenticate(to, from, next),
  },
];


function authenticate(to, from, next) {
  // check if token exists.
  let routesArray = ['Login', 'AnswerQuestion', 'SetupQuestion', 'ForgotPassword', 'ResetPassword', 'Register'];
  if (authHelper.getAuthToken('subUser') && routesArray.includes(to.name)) {
    next({name: 'Dashboard'});
  } else {
    let token = authHelper.getAuthToken('subUser');
    // if exists, check if valid.
    next();
    // authHelper.isAuthTokenValid(token, 'api').then(response => {
    //   next({name: 'Dashboard'});
    // }).catch(error => {
    //   next();
    // })
  }
}

function protectSecurityRoute(to, from, next) {
  if (from.name === 'Login') {
    return next();
  }
  next({name: 'Login'});
}

export default authRoutes;
