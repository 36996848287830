import AdminLayout from '@/views/Layout/AdminLayout.vue';

const Dashboard = () => import(/* webpackChunkName: "dashboard" */ '@/views/Pages/SuperAdmin/Dashboard.vue');
const ChangePassword = () => import(/* webpackChunkName: "ChangePassword" */ '@/views/Pages/User/ChangePassword.vue');

const TrophyCards = () => import('@/views/Pages/SuperAdmin/Cards&Trophies/TrophyCards')
const AddTrophyCard = () => import('@/views/Pages/SuperAdmin/Cards&Trophies/AddTrophyCard')
const EditTrophyCard = () => import('@/views/Pages/SuperAdmin/Cards&Trophies/EditTrophyCard')
const ShowTrophyCard = () => import('@/views/Pages/SuperAdmin/Cards&Trophies/ShowTrophyCard')
const AdsSetIndex = () => import(/* webpackChunkName: "AdsSetIndex" */ '@/views/Pages/SuperAdmin/AdsSet/Index.vue');
const AdsSetShow = () => import(/* webpackChunkName: "AdsSetShow" */ '@/views/Pages/SuperAdmin/AdsSet/Show.vue');
const DiscountAdsShow = () => import(/* webpackChunkName: "DiscountAdsShow" */ '@/views/Pages/SuperAdmin/DiscountAds/Show.vue');
const DiscountAdsEdit = () => import(/* webpackChunkName: "DiscountAdsEdit" */ '@/views/Pages/SuperAdmin/DiscountAds/Edit.vue');
const ManageUsersIndex = () => import(/* webpackChunkName: "ManageUsersIndex" */ '@/views/Pages/SuperAdmin/ManageUsers/Index.vue');
const ManageUsersShow = () => import(/* webpackChunkName: "ManageUsersShow" */ '@/views/Pages/SuperAdmin/ManageUsers/Show.vue');
const ManageUsersEdit = () => import(/* webpackChunkName: "ManageUsersEdit" */ '@/views/Pages/SuperAdmin/ManageUsers/Edit.vue');
const ManageShopShow = () => import(/* webpackChunkName: "ManageShopShow" */ '@/views/Pages/SuperAdmin/ManageShop/Show.vue');
const ManageShopEdit = () => import(/* webpackChunkName: "ManageShopEdit" */ '@/views/Pages/SuperAdmin/ManageShop/Edit.vue');

//Bari Resource Routes
const BariResources = () => import('@/views/Pages/SuperAdmin/BariResource/BariResources')
const StoreBariResource = () => import('@/views/Pages/SuperAdmin/BariResource/StoreBariResource')
const EditBariResource = () => import('@/views/Pages/SuperAdmin/BariResource/EditBariResource')

//Bari Resource Category Routes
const BariResourceCategories = () => import('@/views/Pages/SuperAdmin/BariResource/BariResourceCategory/BariResourceCategories')

//Bari Resource Sub Category Routes
const BariResourceSubCategories = () => import('@/views/Pages/SuperAdmin/BariResource/BariResourceSubCategory/BariResourceSubCategories')

//const Support Email Routes
const SupportEmail = () => import('@/views/Pages/SuperAdmin/SupportEmail/SupportEmail')

//Manage Main Menu Routes
const ManageMenu = () => import('@/views/Pages/SuperAdmin/MainMenu/Menus')

//Quotes Routes
const Quotes = () => import('@/views/Pages/SuperAdmin/Quotes/Quotes')

//Baritastic Program Routes
const BaritasticPrograms = () => import('@/views/Pages/SuperAdmin/BaritasticProgram/BaritasticPrograms')
const ChangeProgramPassword = () => import('@/views/Pages/SuperAdmin/BaritasticProgram/ChangePassword')
const ShowProgram = () => import('@/views/Pages/SuperAdmin/BaritasticProgram/ShowProgram')
const StoreProgram = () => import('@/views/Pages/SuperAdmin/BaritasticProgram/Store');
const EditProgram = () => import('@/views/Pages/SuperAdmin/BaritasticProgram/Edit');

// Tips
const TipsIndex = () => import('@/views/Pages/SuperAdmin/Tips/Index');

// Groups
const GroupsIndex = () => import('@/views/Pages/SuperAdmin/Groups/Index');
const GroupsShow = () => import('@/views/Pages/SuperAdmin/Groups/Show');

//Program User Routes
const ProgramUsers = () => import('@/views/Pages/SuperAdmin/ProgramUser/ProgramUsers')
const StoreProgramUser = () => import('@/views/Pages/SuperAdmin/ProgramUser/StoreProgramUser')
const EditProgramUser = () => import('@/views/Pages/SuperAdmin/ProgramUser/EditProgramUser')
const ShowProgramUser = () => import('@/views/Pages/SuperAdmin/ProgramUser/ShowProgramUser')
const ChangeProgramUserPassword = () => import('@/views/Pages/SuperAdmin/ProgramUser/ChangePasswordProgramUser')

//Event Routes
const Events = () => import('@/views/Pages/SuperAdmin/Event/Events')
const ShowEvent = () => import('@/views/Pages/SuperAdmin/Event/ShowEvent')
const StoreEvent = () => import('@/views/Pages/SuperAdmin/Event/StoreEvent')
const EditEvent = () => import('@/views/Pages/SuperAdmin/Event/EditEvent')


//Location Routes
const Locations = () => import('@/views/Pages/SuperAdmin/Event/Location/Locations');

const routes = [
  {
    path: '/admin',
    component: AdminLayout,
    redirect: '/dashboard',
    name: 'admin.layout',
    meta: { auth: true , super_admin: true},
    children: [
      {
        path: 'dashboard',
        name: 'admin.dashboard',
        component: Dashboard,
      },
      {
        path: 'change-password',
        name: 'admin.change_password',
        component:ChangePassword
      },
      {
        path: 'cards-trophies',
        name: 'TrophyCards',
        component: TrophyCards
      },
      {
        path: 'cards-trophies/store',
        name: 'AddTrophyCard',
        component: AddTrophyCard
      },
      {
        path: 'cards-trophies/:id/edit',
        name: 'EditTrophyCard',
        component: EditTrophyCard
      },
      {
        path: 'cards-trophies/:id/show',
        name: 'ShowTrophyCard',
        component: ShowTrophyCard
      },
      {
        path: 'ads-sets',
        name: 'admin.ads_sets',
        component:AdsSetIndex
      },
      {
        path: 'ads-sets/:id',
        name: 'admin.ads_sets.show',
        component:AdsSetShow
      },
      {
        path: 'discount-ads',
        name: 'admin.discount_ads.show',
        component:DiscountAdsShow
      },
      {
        path: 'discount-ads/edit',
        name: 'admin.discount_ads.edit',
        component:DiscountAdsEdit
      },
      {
        path: 'bari-resources',
        name: 'BariResources',
        component: BariResources
      },
      {
        path: 'bari-resources/store',
        name: 'StoreBariResource',
        component: StoreBariResource
      },
      {
        path: 'bari-resources/:id/edit',
        name: 'EditBariResource',
        component: EditBariResource
      },
      {
        path: 'bari-resources/categories',
        name: 'BariResourceCategories',
        component: BariResourceCategories
      },
      {
        path: 'bari-resources/sub-categories',
        name: 'BariResourceSubCategories',
        component: BariResourceSubCategories
      },
      {
        path: 'manage-shop',
        name: 'admin.manage_shops.show',
        component:ManageShopShow
      },
      {
        path: 'manage-shop/edit',
        name: 'admin.manage_shops.edit',
        component:ManageShopEdit
      },
      {
        path: 'how-to-use-app',
        name: 'admin.how-to-use-app.index',
        component: ()=>import(/* webpackChunkName: "HowToUseAppIndex" */ '@/views/Pages/SuperAdmin/HowToUseApp/Index.vue')
      },
      {
        path: 'how-to-use-app/categories',
        name: 'admin.how-to-use-app.categories',
        component: ()=>import(/* webpackChunkName: "HowToUseAppCategoryIndex" */ '@/views/Pages/SuperAdmin/HowToUseApp/Category/Index.vue')
      },
      {
        path: 'support-email',
        name: 'admin.support_email',
        component: SupportEmail
      },
      {
        path: 'manage-menu',
        name: 'ManageMenu',
        component: ManageMenu
      },
      {
        path: 'quotes',
        name: 'admin.quotes',
        component:Quotes
      },
      {
        path: 'manage-users/index',
        name: 'admin.manage-users.index',
        component:ManageUsersIndex
      },
      {
        path: 'manage-users/show/:id',
        name: 'admin.manage-users.show',
        component:ManageUsersShow
      },
      {
        path: 'manage-users/edit/:id',
        name: 'admin.manage-users.edit',
        component:ManageUsersEdit
      },
      {
        path: 'baritastic-program',
        name: 'admin.baritastic_program',
        component: BaritasticPrograms
      },
      {
        path: 'baritastic-program/store',
        name: 'admin.baritastic_program.store',
        component: StoreProgram
      },
      {
        path: 'baritastic-program/:id/edit',
        name: 'admin.baritastic_program.edit',
        component: EditProgram
      },
      {
        path: 'baritastic-program/:id/change-password',
        name: 'admin.baritastic_program.change_password',
        component: ChangeProgramPassword
      },
      {
        path: 'baritastic-program/:id/show',
        name: 'admin.baritastic_program.show',
        component: ShowProgram
      },
      {
        path: 'tips',
        name: 'admin.tips.index',
        component: TipsIndex
      },
      {
        path: 'groups',
        name: 'admin.groups.index',
        component: GroupsIndex
      },
      {
        path: 'groups/:id/show',
        name: 'admin.groups.show',
        component: GroupsShow
      },
      {
        path: 'program-user',
        name: 'admin.program_user',
        component: ProgramUsers
      },
      {
        path: 'program-user/store',
        name: 'admin.program_user.store',
        component: StoreProgramUser
      },
      {
        path: 'program-user/:id/edit',
        name: 'admin.program_user.edit',
        component: EditProgramUser
      },
      {
        path: 'program-user/:id/show',
        name: 'admin.program_user.show',
        component: ShowProgramUser
      },
      {
        path: 'program-user/:id/change-password',
        name: 'admin.program_user.change_password',
        component: ChangeProgramUserPassword
      },
      {
        path: 'event',
        name: 'admin.event',
        component: Events
      },
      {
        path: 'event/:id/show',
        name: 'admin.event.show',
        component: ShowEvent
      },
      {
        path: 'event/store',
        name: 'admin.event.store',
        component: StoreEvent
      },
      {
        path: 'event/:id/edit',
        name: 'admin.event.edit',
        component: EditEvent
      },
      {
        path: 'event/location',
        name: 'admin.event.location',
        component: Locations
      },
    ]
  }
];


export default routes;
