import axios from "axios";

export default {
  namespaced: true,
  state: {
    base_url: process.env.VUE_APP_BASE_API_URL,
    event: {
      place_new_id: '',
      place_id: 1,
      push_notification: 0,
      five_minute_earlier_reminder: 0,
      custom_reminder_message: 'We are starting <Event Name> in 5 minutes.',
      stage: '',
      stages: [],
      user_id: 0,
      event_title: '',
      event_description: '',
      type: 1,
      time: '',
      end_time: '',
      date: '',
      timezone: '',
      job_id: '',
      location_id: '',
      location: '',
      email: '',
      status: 1,
      venue_type: '',
      weight_loss_type: '',
      patient_type: '',
      hosted_by: '',
    },
    sourceGetAll: '',
  },
  getters: {
    getEvent(state) {
      return state.event;
    }
  },
  actions: {
    /**
     * Reset Logout Time fields
     * @param commit
     * @private
     */
    _resetLogoutTimer({commit}) {
      commit('toggleSessionCheck', true, {root: true})
      commit('updateSessionTime', 0, {root: true})
    },

    /**
     * Get Events
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */
    _getEvents({state, dispatch}, {params}) {
      return new Promise((resolve, reject) => {
        if(state.sourceGetAll){
          state.sourceGetAll.cancel()
        }
        const CancelToken = axios.CancelToken;
        state.sourceGetAll = CancelToken.source();
        axios.get(`${state.base_url}/api/admin/event`, {params, cancelToken: state.sourceGetAll.token})
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Get Requested Event
     * @param context - Contains the commit and state object
     * @param params - Parameters for the API
     * @returns {Promise<unknown>}
     * @private
     */
    _getRequestedEvent({state, dispatch}, params) {
      return new Promise((resolve, reject) => {
        axios.get(`${state.base_url}/api/admin/event/${params.id}`)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Store Event
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */
    _storeEvent({state, dispatch}, {formData}) {
      return new Promise((resolve, reject) => {
        axios.post(`${state.base_url}/api/admin/event/store`, formData)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Store Event
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */
    _updateEvent({state, dispatch}, {formData}) {
      return new Promise((resolve, reject) => {
        axios.post(`${state.base_url}/api/admin/event/update`, formData)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Update Event(s) status
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * * @returns {Promise<unknown>}
     * @private
     */
    _updateEventStatus({state, dispatch}, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`${state.base_url}/api/admin/event/toggle-status`, payload)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          }).catch(error => {
          reject(error)
        })
      })
    },

    /**
     * Delete Event(s)
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * * @returns {Promise<unknown>}
     * @private
     */
    _deleteEvent({state, dispatch}, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`${state.base_url}/api/admin/event/delete`, payload)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          }).catch(error => {
          reject(error)
        })
      })
    },
  }
}
