import axios from "axios";

export default {
  namespaced: true,
  state: {
    base_url: process.env.VUE_APP_BASE_API_URL,
    api_url: process.env.VUE_APP_BASE_API_URL + '/api/content/content-sub-categories',
    menu:[
      {
        id:11,
        label:'videos',
        weight: 11,
        pluralTitle: 'Videos',
        singularTitle: 'Video',
        categoryForeignKey: 'our_video_category_id',
        sctcRelation : 'video_category',
      }
    ]
  },

  getters: {
    getMenuItem: (state) => {
      return state.menu;
    },
  },

  mutations: {},

  actions: {
    _apiAfterCode({dispatch, commit, getters, rootGetters}) {
      commit('toggleSessionCheck', true, {root: true})
      commit('updateSessionTime', 0, {root: true})
    },

    _APIIndex(context, payload = null) {
      return new Promise((resolve, reject) => {
        axios.get(context.state.api_url, {params: payload}).then((response) => {
          context.dispatch('_apiAfterCode');
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      })
    },

    _APIGetAll(context, payload = null) {
      return new Promise((resolve, reject) => {
        axios.get(context.state.api_url+'/by-category-id', {params: payload}).then((response) => {
          context.dispatch('_apiAfterCode');
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      })
    },

    _APIToggleStatus(context, payload = null) {
      return new Promise((resolve, reject) => {
        axios.post(context.state.api_url+'/toggle-status',  payload).then((response) => {
          context.dispatch('_apiAfterCode');
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      })
    },

    _APIShow: (context, payload) => {
      return new Promise((resolve, reject) => {
        axios.get(context.state.api_url + `/${payload.id}`, {params: payload}).then((response) => {
          context.dispatch('_apiAfterCode');
          resolve(response);
        }).catch(error => {
          reject(error)
        });
      })
    },

    _APIDelete: (context, payload = null) => {
      let config = {
        params: payload
      };

      return new Promise((resolve, reject) => {
        axios.delete(context.state.api_url + '/destroy', config).then((response) => {
          context.dispatch('_apiAfterCode');
          resolve(response);
        }).catch(error => {
          reject(error)
        });
      })
    },

    _APIStore: (context, payload = null) => {
      return new Promise((resolve, reject) => {
        axios.post(context.state.api_url + '/store', payload).then((response) => {
          context.dispatch('_apiAfterCode');
          resolve(response);
        }).catch(error => {
          reject(error)
        });
      })
    },

    _APIUpdate: (context, payload = null) => {
      return new Promise((resolve, reject) => {
        axios.put(context.state.api_url + '/update', payload).then((response) => {
          context.dispatch('_apiAfterCode');
          resolve(response);
        }).catch(error => {
          reject(error)
        });
      })
    }
  }
};
