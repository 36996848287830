<template>
    <div class="position-relative">
        <terms-and-conditions-modal :user-id="user.id" @closeTermsAndConditionsModal="closeTermsAndConditionsModal">
        </terms-and-conditions-modal>
        <div v-if="$store.state.modals.downloadingReport"
            class="position-fixed mt--5 d-flex bg-white report-download-div pr-5 pl-3 pt-3">
            <img alt="image" src="/img/download.gif" class="report-download-image">
            <p class="report-download-heading">Your report is being prepared. Leave this window open.<br>It may take a few
                minutes.
                <button class="cancel-button float-right" @click="cancelReport">Cancel</button>
            </p>
        </div>
        <no-events-message-modal />
    </div>
</template>
<script>
import TermsAndConditionsModal from "@/views/Layout/TermsAndConditionsModal";
import NoEventsMessageModal from "@/views/Layout/NoEventsMessageModal";

export default {
    components: {
        TermsAndConditionsModal, NoEventsMessageModal
    },
    data() {
        return {

        };
    },  
    computed:{
        user(){
            return this.$store.getters.getAuthUser;
        }
    },
    methods: {
        /**
         * Close the Terms and Conditions Modal and logout the user
         */
        closeTermsAndConditionsModal() {
            this.logout()
        },
        logout() {
            this.startLoading();
            this.$store.dispatch('_logout').then(response => {
                window.location.assign('/login');
                // this.$router.push({name: 'Login'});
            }).catch(err => {

            }).finally(() => {
                this.stopLoading();
            });
        },
        cancelReport() {
            this.$store.commit('toggleDownloadReport', false);
        },
    }
};
</script>
  
<style scoped>
.report-download-heading {
    font: normal normal 600 14px/25px Open Sans;
    color: #000000;
}

.custom-label {
    float: right;
    color: #525f7fb0;
    font-size: 0.875rem;
    font-weight: 400;
    padding: 0rem 1rem;
}

.report-download-div {
    left: 50%;
    transform: translate(-50%, 0);
    box-shadow: 0px 3px 30px #0000001A;
    border-radius: 6px;
    z-index: 1050;
}

.cancel-button {
    background: transparent;
    border: 0;
    font: normal normal 600 11px/15px Open Sans;
    color: #BB2255;
    padding-top: 6px;
    margin-right: -5px;
}

.report-download-image {
    width: 80px;
    height: 80px;
    margin-top: -15px;
    margin-right: -5px;
}
</style>
  