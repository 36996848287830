import { store } from '@/vuex/store';

export default {
  // Sets Token in Localstorage.
  setAuthToken: (token, userType) => {
    token = JSON.stringify(token);
    window.localStorage.setItem(userType, token);
  },

  // Gets Token From Localstorage.
  getAuthToken: (userType) => {
    return JSON.parse(window.localStorage.getItem(userType));
    // return JSON.parse(window.localStorage.getItem(userType));
  },

  // Deletes a Token in Localstorage.
  deleteAuthToken: (userType) => {
    window.localStorage.removeItem(userType);
  },

  // Sets Refresh Token in Localstorage.
  setRefreshToken: (token) => {
    token = btoa(token);
    window.localStorage.setItem(`refresh_token`, token);
  },

  // Gets Refresh Token From Localstorage.
  getRefreshToken: () => {
    return atob(window.localStorage.getItem(`refresh_token`));
  },

  // Deletes a Refresh Token in Localstorage.
  deleteRefreshToken: () => {
    window.localStorage.removeItem(`refresh_token`);
  },

  // Is Token Valid?isAuthTokenValid
  isAuthTokenValid: (token, userType) => {
    return new Promise((resolve, reject) => {
      axios.get(`${store.state.base_url}/api/token/validate?token=${token}&guard=${userType}`).then(response => {
        store.commit('toggleSessionCheck', true)
        store.commit('updateSessionTime', 0)
        resolve(response);
      }).catch(err => {
        reject(err);
      })
    });
  },

  // Sets IsPasswordChanged in Localstorage.
  setIsPasswordChanged: (isPasswordChanged) => {
    window.localStorage.setItem('isPasswordChanged', isPasswordChanged);
    store.commit('setAuthUserPasswordChanged', isPasswordChanged)
  },

  // Gets IsPasswordChanged From Localstorage.
  getIsPasswordChanged: () => {
    return window.localStorage.getItem('isPasswordChanged');
  },

  // Deletes IsPasswordChanged in Localstorage.
  deleteIsPasswordChanged: () => {
    window.localStorage.removeItem('isPasswordChanged');
  },

  getManagePatientsSavedSearch: () => {
    const savedSearch = window.localStorage.getItem('savedSearch')
    return savedSearch ? JSON.parse(atob(savedSearch)) : null
  },

  setManagePatientsSavedSearch: (savedSearch) => {
    savedSearch = JSON.stringify(savedSearch)
    window.localStorage.setItem('savedSearch', btoa(savedSearch))
  },

  deleteManagePatientsSavedSearch: (savedSearch) => {
    window.localStorage.removeItem('savedSearch')
  },

  getEventCalendarSavedSearch: () => {
    const savedSearch = window.localStorage.getItem('savedEventCalendarMonth')
    return savedSearch ? atob(savedSearch) : null
  },

  setEventCalendarSavedSearch: (savedSearch) => {
    window.localStorage.setItem('savedEventCalendarMonth', btoa(savedSearch))
  },

  deleteEventCalendarSavedSearch: () => {
    window.localStorage.removeItem('savedEventCalendarMonth')
  },

  setSelfServiceCheck: (selfService) => {
    window.localStorage.setItem('self_service', selfService)
  },

  getSelfServiceCheck: () => {
    return window.localStorage.getItem('self_service')
  },

  deleteSelfServiceCheck: () => {
    window.localStorage.removeItem('self_service')
  },
  setManageUsersSuperAdminSavedSearch: (savedSearch) => {
    savedSearch = JSON.stringify(savedSearch)
    window.localStorage.setItem('manageUserSuperAdminSavedSearch', btoa(savedSearch))
  },
  getManageUsersSuperAdminSavedSearch: () => {
    const savedSearch = window.localStorage.getItem('manageUserSuperAdminSavedSearch');
    return savedSearch ? JSON.parse(atob(savedSearch)) : {}
  },
  removeManageUsersSuperAdminSavedSearch: () => {
    window.localStorage.removeItem('manageUserSuperAdminSavedSearch');
  },
  setActiveTabInCustomizePipeline: (tab) => {
    window.localStorage.setItem('customizePipelineActiveTab', tab)
  },
  getActiveTabInCustomizePipeline: () => {
    const tab = window.localStorage.getItem('customizePipelineActiveTab');
    return tab ? tab : '0'
  },
  removeActiveTabInCustomizePipeline: () => {
    window.localStorage.removeItem('customizePipelineActiveTab');
  }
}
