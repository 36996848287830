import axios from "axios";

export default {
  namespaced: true,
  state: {
    base_url: process.env.VUE_APP_BASE_API_URL,
    modals: {
      preview: false
    }
  },
  getters: {
    
  },
  mutations: {
    showPreviewModal(state, payload) {
      state.modals.preview = true;
    },
    hidePreviewModal(state, payload) {
      state.modals.preview = false;
    },
  },
  actions: {
    
    /**
     * Reset Logout Time fields
     * @param commit
     * @private
     */
    _resetLogoutTimer({commit}) {
      commit('toggleSessionCheck', true, {root: true})
      commit('updateSessionTime', 0, {root: true})
    },
      /**
     * Get Manage Shop
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */
      _getManageShop({state, dispatch}) {
        return new Promise((resolve, reject) => {
          axios.get(`${state.base_url}/api/admin/manage-shop`)
            .then(response => {
              dispatch('_resetLogoutTimer')
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },

    _storeManageShop({state, dispatch}, {fd}) {
      return new Promise((resolve, reject) => {
        axios.post(`${state.base_url}/api/admin/manage-shop/store`, fd, { headers: { 'content-type': 'multipart/form-data' } })
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    
  }
};
