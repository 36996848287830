import axios from "axios";

export default {
  namespaced: true,
  state: {
    base_url: process.env.VUE_APP_BASE_API_URL,
    automationRuleType: [
      {value: 1, label: 'Surgery Date',label2:'Surgery Date', description: 'Surgery Date is passed x days'},
      {value: 6, label: 'Surgery Date',label2:'Surgery Date', description: 'Surgery Date is added'},
      {value: 2, label: 'Checklist Item',label2:'Checklist Item', description: 'Checklist item is completed'},
      {value: 4, label: 'Checklist % Complete',label2:'Checklist', description: '90% complete trigger but only happens for patients past xyz stage (ignores patients before that stage).'},
      {value: 5, label: 'Days in Stage',label2:'Days in Stage', description: 'if patient is X many days in this stage, then move patient to Y pipeline.'},
    ],
    tempStages: [
      {value: 1, label: 'Pre-Op'},
      {value: 2, label: 'Post-Op'},
      {value: 3, label: 'Weight-Management'}
    ],
    refreshPipelineAutomationRuleListing:false,
  },
  getters: {
    getAutomationRuleType(state) {
      return state.automationRuleType;
    },
    getRefreshPipelineAutomationRuleListing(state) {
      return state.refreshPipelineAutomationRuleListing;
    },
  },
  mutations: {
    updateNewTodoSettingList(state, payload) {
      state.newTodoSettingList = payload
    },
    updateRefreshPipelineAutomationRuleListing(state, payload) {
      state.refreshPipelineAutomationRuleListing = payload
    },
  },
  actions: {
    _apiAfterCode({dispatch, commit, getters, rootGetters}) {
      commit('toggleSessionCheck', true, {root: true})
      commit('updateSessionTime', 0, {root: true})
    },
    _getNewTodoSettingList(context, payload) {
      return new Promise((resolve, reject) => {
        axios.get(context.state.base_url + '/api/pipeline-automation-rule/new-todo-settings', {params:payload}).then((response) => {
          context.dispatch('_apiAfterCode');
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      })
    },
    _getPipelineStages(context, payload) {
      return new Promise((resolve, reject) => {
        // axios.get(context.state.base_url + '/api/get-pipeline-stages', {params:payload}).then((response) => {
        axios.get(context.state.base_url + '/api/pipelines', {params:payload}).then((response) => {
          context.dispatch('_apiAfterCode');
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      })
    },
    _deleteAutomationRule: (context,payload) => {
      let config = {
        params:payload
      };
      return new Promise((resolve, reject) => {
        axios.delete(context.state.base_url + '/api/pipeline-automation-rule/delete',config).then((response) => {
          context.dispatch('_apiAfterCode');
          resolve(response);
        }).catch(error => {
          reject(error)
        });
      })
    },
    _toggleActiveAutomationRule: (context,payload) => {
      return new Promise((resolve, reject) => {
        axios.post(context.state.base_url + '/api/pipeline-automation-rule/toggle-active',payload).then((response) => {
          context.dispatch('_apiAfterCode');
          resolve(response);
        }).catch(error => {
          reject(error)
        });
      })
    },
    _updatePriorityAutomationRule: (context,payload) => {
      return new Promise((resolve, reject) => {
        axios.post(context.state.base_url + '/api/pipeline-automation-rule/update-priority',payload).then((response) => {
          context.dispatch('_apiAfterCode');
          resolve(response);
        }).catch(error => {
          reject(error)
        });
      })
    },
    _updateAutomationRule: (context,payload) => {
      return new Promise((resolve, reject) => {
        axios.put(context.state.base_url + '/api/pipeline-automation-rule/update',payload).then((response) => {
          context.dispatch('_apiAfterCode');
          resolve(response);
        }).catch(error => {
          reject(error)
        });
      })
    },
    _storeAutomationRule: (context,payload) => {
      return new Promise((resolve, reject) => {
        axios.post(context.state.base_url + '/api/pipeline-automation-rule/store',payload).then((response) => {
          context.dispatch('_apiAfterCode');
          resolve(response);
        }).catch(error => {
          reject(error)
        });
      })
    },
  }
};
