var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('common-layout',{scopedSlots:_vm._u([{key:"modals",fn:function(){return [_c('add-dynamic-content-section-modal',{attrs:{"show-modal":_vm.dynamicSectionModal},on:{"hideModal":function($event){_vm.dynamicSectionModal = false},"fetchDynamicSection":_vm.fetchDynamicSection}}),_c('notifications')]},proxy:true},{key:"sidebar",fn:function(){return [_c('side-bar',[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
            name: 'Dashboard',
            icon: 'ni ni-shop text-primary',
            path: '/dashboard'
          }}}),((_vm.userType == 'program_user' && _vm.programUserWithSidebarMenuList.manage_user == 1 && _vm.programUserWithSidebarMenuList.self_service != 1)
        ||  _vm.userType === 'sub_user')?_c('sidebar-item',{attrs:{"link":{
            name: 'Patients',
            icon: 'far fa-user text-primary',
          }}},[((_vm.userType == 'program_user' && _vm.programUserWithSidebarMenuList.manage_user == 1)
          ||  _vm.userType === 'sub_user')?_c('sidebar-item',{attrs:{"link":{ name: 'Manage Patients', path: '/manage-patients' }}}):_vm._e(),((_vm.userType == 'program_user' && _vm.programUserWithSidebarMenuList.pipeline_status == 1)
           ||  _vm.userType === 'sub_user')?_c('sidebar-item',{attrs:{"link":{ name: 'Patient Pipeline', path: '/pipeline' }}}):_vm._e()],1):_vm._e(),((_vm.userType == 'program_user' && _vm.programUserWithSidebarMenuList.seminar_to_surgery == 1 && _vm.programUserWithSidebarMenuList.self_service != 1)
         ||  _vm.userType === 'sub_user')?_c('sidebar-item',{attrs:{"link":{
            name: 'Reports',
            icon: 'far fa-file-alt text-primary',
          }}},[((_vm.userType == 'program_user' && _vm.programUserWithSidebarMenuList.seminar_to_surgery == 1)
           ||  _vm.userType === 'sub_user')?_c('sidebar-item',{attrs:{"link":{ name: 'Pipeline Reports', path: '/patient-pipeline-report' }}}):_vm._e(),((_vm.userType == 'program_user' && _vm.programUserWithSidebarMenuList.seminar_to_surgery == 1)
           ||  _vm.userType === 'sub_user')?_c('sidebar-item',{attrs:{"link":{ name: 'Checklist Reports', path: '/seminar2surgery' }}}):_vm._e(),((_vm.userType == 'program_user' && _vm.programUserWithSidebarMenuList.seminar_to_surgery == 1)
           ||  _vm.userType === 'sub_user')?_c('sidebar-item',{attrs:{"link":{ name: 'Surgery Reports'}}},[((_vm.userType == 'program_user' && _vm.programUserWithSidebarMenuList.seminar_to_surgery == 1)
           ||  _vm.userType === 'sub_user')?_c('sidebar-item',{attrs:{"link":{ name: 'Pre-Op Report', path: '/surgery-date-patients-report' }}}):_vm._e(),((_vm.userType == 'program_user' && _vm.programUserWithSidebarMenuList.seminar_to_surgery == 1)
           ||  _vm.userType === 'sub_user')?_c('sidebar-item',{attrs:{"link":{ name: 'Post-Op Report', path: '/post-op-patients-report' }}}):_vm._e()],1):_vm._e(),((_vm.userType == 'program_user' && _vm.programUserWithSidebarMenuList.seminar_to_surgery == 1)
           ||  _vm.userType === 'sub_user')?_c('sidebar-item',{attrs:{"link":{ name: 'Never Used App', path: '/not-connected-patients-report' }}}):_vm._e(),((_vm.userType == 'program_user' && _vm.programUserWithSidebarMenuList.seminar_to_surgery == 1)
           ||  _vm.userType === 'sub_user')?_c('sidebar-item',{attrs:{"link":{ name: 'Support Group Attendance', path: '/events-rsvp-list' }}}):_vm._e()],1):_vm._e(),((_vm.userType == 'program_user' && _vm.programUserWithSidebarMenuList.alert_notification == 1 && _vm.programUserWithSidebarMenuList.self_service != 1)
         ||  _vm.userType === 'sub_user')?_c('sidebar-item',{attrs:{"link":{
            name: 'Alerts',
            icon: 'far fa-bell text-primary',
            path: '/alerts'
          }}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{
            name: 'Messages',
            icon: 'far fa-comment-alt text-primary',
          }}},[_c('sidebar-item',{attrs:{"link":{ name: 'On Demand', path: '/on-demand-messages' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Scheduled Messages', path: '/scheduled-messages' }}}),_c('sidebar-item',{attrs:{"link":{ name:'Automatic Messages', path:'/automatic-messages' }}})],1),(((_vm.userType == 'program_user' && (_vm.programUserWithSidebarMenuList.event == 1 || _vm.programUserWithSidebarMenuList.self_service == 1))||  _vm.userType === 'sub_user')&& _vm.checkUserMenuStatus('Calendar'))?_c('sidebar-item',{attrs:{"link":{
            name: 'Event Calendar',
            icon: 'far fa-calendar-minus text-primary',
            path: '/event-calender'
          }}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{
            name: 'Content',
            icon: 'far fa-file-alt text-primary',
          }}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.userType == 'sub_user'),expression:"userType == 'sub_user'"}],staticClass:"nav-link dynamic-section-button",attrs:{"role":"button"},on:{"click":_vm.showAddDynamicSectionModal}},[_c('img',{attrs:{"src":"/img/plus.svg","width":"20","height":"20","alt":""}}),_vm._v("Add New Section ")]),(_vm.authUser.program_id == _vm.henryFordId)?_c('sidebar-item',{attrs:{"link":{
            name: 'Weight Management',
            icon: 'far fa-file-alt text-primary',
          }}},[((_vm.userType == 'program_user' && (_vm.programUserWithSidebarMenuList.contact == 1 || _vm.programUserWithSidebarMenuList.self_service == 1))
          ||  _vm.userType === 'sub_user')?_c('sidebar-item',{attrs:{"link":{ name: 'Contact Us', path: '/contact-us?weightLossType=1', key:'abc'}}}):_vm._e(),((_vm.userType == 'program_user' && (_vm.programUserWithSidebarMenuList.non_surgeries_wight == 1 || _vm.programUserWithSidebarMenuList.self_service == 1))
            ||  _vm.userType === 'sub_user')?_c('sidebar-item',{attrs:{"link":{ name: 'Non Surgical Weight Loss', path: '/non-surgical-weight-loss' }}}):_vm._e()],1):_vm._e(),(_vm.authUser.program_id == _vm.henryFordId)?_c('sidebar-item',{attrs:{"link":{
            name: 'Weight Loss Surgery',
            icon: 'far fa-file-alt text-primary',
          }}},[_c('content-menu-items',{ref:"contentMenuItemsRef",attrs:{"dynamic-content-sections-list":_vm.dynamicContentSectionsList,"programUserWithSidebarMenuList":_vm.programUserWithSidebarMenuList,"userType":_vm.userType,"henryFordId":_vm.henryFordId,"programId":_vm.authUser.program_id}})],1):_vm._e(),(_vm.authUser.program_id != _vm.henryFordId)?_c('content-menu-items',{ref:"contentMenuItemsRef",attrs:{"dynamic-content-sections-list":_vm.dynamicContentSectionsList,"programUserWithSidebarMenuList":_vm.$store.state.programUserWithSidebarMenuList,"sub-menu-managers":_vm.$store.state.fromSubMenuManagers,"userType":_vm.userType,"henryFordId":_vm.henryFordId,"programId":_vm.authUser.program_id}}):_vm._e()],1),(_vm.userType == 'sub_user' || _vm.programUserWithSidebarMenuList.self_service != 1)?_c('sidebar-item',{attrs:{"link":{
            name: 'Templates',
            icon: 'far fa-sticky-note text-primary'
          }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Checklist Templates', path: '/checklist-templates' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Message Templates', path: '/message-templates' }}})],1):_vm._e(),((_vm.userType == 'program_user' && _vm.programUserWithSidebarMenuList.manage_user == 1 && _vm.programUserWithSidebarMenuList.self_service != 1)
          ||  _vm.userType === 'sub_user')?_c('sidebar-item',{attrs:{"link":{
            name: 'Patient Tags',
            icon: 'fas fa-list text-primary',
            path: '/user-category'
          }}}):_vm._e(),(_vm.userType === 'sub_user')?_c('sidebar-item',{attrs:{"link":{
            name: 'Manage Menu',
            icon: 'fas fa-list text-primary',
            path: '/manage-menu'
          }}}):_vm._e(),(_vm.userType === 'program_user')?_c('sidebar-item',{attrs:{"link":{ name: 'Help', icon: 'far fa-question-circle text-primary' }}},[(_vm.userType === 'program_user')?_c('sidebar-item',{attrs:{"link":{ name: 'Baritastic Support', path: '/support-messages/baritastic-support/store' }}}):_vm._e(),(_vm.userType === 'program_user')?_c('sidebar-item',{attrs:{"link":{ name: 'Support Group Assistance', path: '/support-messages/support-groups/store' }}}):_vm._e(),(_vm.userType === 'program_user')?_c('sidebar-item',{attrs:{"link":{ name: 'Request Vitamin Samples', path: '/support-messages/vitamin-supplements/store' }}}):_vm._e()],1):_vm._e()],1)],2)]},proxy:true},{key:"navbar",fn:function(){return [_c('dashboard-navbar',{attrs:{"type":_vm.$route.meta.navbarType}})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }