import DashboardLayout from '@/views/Layout/DashboardLayout.vue';
import authHelper from "../util/authHelper";
import ResetPassword from "@/views/Pages/ResetPassword";
import { store } from '@/vuex/store';

// Automatic Messages
const AutomaticMessages = () => import('@/views/Components/Messages/AutomaticMessages.vue');

// Calendar
const Calendar = () => import(/* webpackChunkName: "extra" */ '@/views/Calendar/Calendar.vue');
// Charts
const Charts = () => import(/* webpackChunkName: "dashboard" */ '@/views/Charts.vue');

// Components pages
const Buttons = () => import(/* webpackChunkName: "components" */ '@/views/Components/Buttons.vue');
const Cards = () => import(/* webpackChunkName: "components" */ '@/views/Components/Cards.vue');
const GridSystem = () => import(/* webpackChunkName: "components" */ '@/views/Components/GridSystem.vue');
const Notifications = () => import(/* webpackChunkName: "components" */ '@/views/Components/Notifications.vue');
const Icons = () => import(/* webpackChunkName: "components" */ '@/views/Components/Icons.vue');
const Typography = () => import(/* webpackChunkName: "components" */ '@/views/Components/Typography.vue');

// Dashboard pages
const Dashboard = () => import(/* webpackChunkName: "dashboard" */ '@/views/Pages/Dashboard/Dashboard.vue');
const AlternativeDashboard = () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard/AlternativeDashboard.vue');
const Widgets = () => import(/* webpackChunkName: "dashboard" */ '@/views/Widgets.vue');

// Event Pages
const EventLocationList = () => import(/* webpackChunkName: "calendarEvent" */ "@/views/Pages/EventCalender/EventLocation/EventLocationList")
const EventList = () => import(/* webpackChunkName: "calendarEvent" */ '@/views/Pages/EventCalender/EventList.vue')
const EventForm = () => import(/* webpackChunkName: "calendarEvent" */ '@/views/Pages/EventCalender/EventForm.vue')
const EventDetail = () => import(/* webpackChunkName: "calendarEvent" */ '@/views/Pages/EventCalender/EventDetail.vue')

// Forms pages
const FormElements = () => import(/* webpackChunkName: "forms" */ '@/views/Forms/FormElements.vue');
const FormComponents = () => import(/* webpackChunkName: "forms" */ '@/views/Forms/FormComponents.vue');
const FormValidation = () => import(/* webpackChunkName: "forms" */ '@/views/Forms/FormValidation.vue');

// Logs
const WeightLog = () => import(/* webpackChunkName: "weight-log" */ '@/views/Pages/Logs/WeightLog/WeightLog.vue');
const FoodLog = () => import(/* webpackChunkName: "food-log" */ '@/views/Pages/Logs/FoodLog/FoodLog.vue');

// Logs
const NutritionAlerts = () => import(/* webpackChunkName: "alerts" */ '@/views/Pages/Alerts/Alerts.vue');

const ChecklistItems = () => import(/* webpackChunkName: "alerts" */ '@/views/Pages/Templates/Checklists/ChecklistItems.vue');

// Maps pages
const GoogleMaps = () => import(/* webpackChunkName: "extra" */ '@/views/Maps/GoogleMaps.vue');
const VectorMaps = () => import(/* webpackChunkName: "extra" */ '@/views/Maps/VectorMaps.vue');

// Messages
const ScheduledMessages = () => import(/* webpackChunkName: "scheduledmessages" */ '@/views/Pages/Messages/ScheduledMessages/ScheduledMessages.vue');
const ShowScheduledMessage = () => import(/* webpackChunkName: "showscheduledmessage"*/ '@/views/Pages/Messages/ScheduledMessages/ShowScheduledMessage.vue')
const OnDemandMessages = () => import(/* webpackChunkName: "OnDemandMessages"*/'@/views/Pages/Messages/OnDemandMessages/OnDemandMessages');
const ShowOnDemandMessage = () => import(/* webpackChunkName: "OnDemandMessages"*/'@/views/Pages/Messages/OnDemandMessages/ShowOnDemandMessage');

// Nutrition reports
const NutritionReport = () => import(/* webpackChunkName: "nutrition" */ '@/views/Pages/NutritionReport/NutritionReport.vue');

const MessageTemplate = () => import(/* webpackChunkName: "messageTemplates" */ '@/views/Pages/Alerts/MessageTemplate.vue');

// Patients pages
const ManagePatients = () => import(/* webpackChunkName: "manage-patients" */ '@/views/Pages/Patients/ManagePatients.vue');
const PatientDetail = () => import(/* webpackChunkName: "pipeline" */ '@/views/Pages/Patients/PatientDetail.vue');
const EditPatientDetail = () => import(/* webpackChunkName: "pipeline" */ '@/views/Pages/Patients/EditPatient.vue');
const AddPatient = () => import(/* webpackChunkName: "pipeline" */ '@/views/Pages/Patients/AddPatient.vue');

const ChecklistTemplates = () => import(/* webpackChunkName: "checklist-templates" */ '@/views/Pages/Templates/ChecklistTemplates.vue');

// Pages
const User = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/UserProfile.vue');
const TimeLine = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/TimeLinePage.vue');

// Pipeline patients
const PipelinePatients = () => import(/* webpackChunkName: "pipeline" */ '@/views/Pages/Pipeline/Pipeline.vue');
const CustomizePipeline = () => import(/* webpackChunkName: "pipeline" */ '@/views/Pages/Pipeline/CustomizePipeline.vue');

//Seminar 2 Surgery
const Seminar2Surgery = () => import(/* webpackChunkName: "seminar2surgery" */ '@/views/Pages/Seminar2Surgery/Seminar2Surgery.vue');
const PipelineReport = () => import('@/views/Pages/Seminar2Surgery/PipelinePatientsReport/PipelinePatients.vue');

// TableList pages
const RegularTables = () => import(/* webpackChunkName: "tables" */ '@/views/Tables/RegularTables.vue');
const SortableTables = () => import(/* webpackChunkName: "tables" */ '@/views/Tables/SortableTables.vue');
const PaginatedTables = () => import(/* webpackChunkName: "tables" */ '@/views/Tables/PaginatedTables.vue');

const ShowInsuranceCoordinator = () => import(/* webpackChunkName: "show-insurance-coordinator" */ '@/views/Pages/Templates/InsuranceCoordinator/ShowInsuranceCoordinator.vue');
const AddInsuranceCoordinator = () => import(/* webpackChunkName: "add-insurance-coordinator" */ '@/views/Pages/Templates/InsuranceCoordinator/AddInsuranceCoordinator.vue');
const EditInsuranceCoordinator = () => import(/* webpackChunkName: "edit-insurance-coordinator" */ '@/views/Pages/Templates/InsuranceCoordinator/EditInsuranceCoordinator.vue');

const Checklist = () => import(/* webpackChunkName: "checklist" */ '@/views/Pages/Checklists/Checklist.vue');
const StoreChecklistTemplate = () => import(/* webpackChunkName: "checklist" */ '@/views/Pages/Templates/Checklists/StoreChecklistTemplate.vue');
const EditChecklistTemplate = () => import(/* webpackChunkName: "checklist" */ '@/views/Pages/Templates/Checklists/EditChecklistTemplate.vue');
const CopyChecklistTemplate = () => import(/* webpackChunkName: "checklist" */ '@/views/Pages/Templates/Checklists/CopyChecklistTemplate.vue');

// User Categories
const UserCategory = () => import(/* webpackChunkName: "userCategories" */ '@/views/Pages/UserCategory/UserCategory.vue');
const CategoryForm = () => import(/* webpackChunkName: "userCategories" */ '@/views/Pages/UserCategory/CategoryForm.vue');

// Content Section
const Contacts = () => import(/* webpackChunkName: "contacts" */ '@/views/Pages/ContentSection/Contacts/Contacts.vue');
const ShowContact = () => import(/* webpackChunkName: "contacts" */ '@/views/Pages/ContentSection/Contacts/ShowContact');
const EditContact = () => import(/* webpackChunkName: "contacts" */ '@/views/Pages/ContentSection/Contacts/EditContact');
const StoreContact = () => import(/* webpackChunkName: "contacts" */ '@/views/Pages/ContentSection/Contacts/StoreContact');

const CoordinatorCategories = () => import(/* webpackChunkName: "CoordinatorCategories" */ '@/views/Pages/ContentSection/CoordinatorCategories/CoordinatorCategories.vue');

const CoordinatorSubCategories = () => import(/* webpackChunkName: "CoordinatorSubCategories" */ '@/views/Pages/ContentSection/CoordinatorSubCategories/CoordinatorSubCategories.vue');

const CoordinatorLastCategories = () => import(/* webpackChunkName: "CoordinatorLastCategories" */ '@/views/Pages/ContentSection/CoordinatorLastCategories/CoordinatorLastCategories.vue');
const OurWebsite = () => import  ("@/views/Pages/ContentSection/OurWebsite");
const JoinUsOnFacebook = () => import  ("@/views/Pages/ContentSection/JoinUsOnFacebook");
const PatientPortal = () => import  ("@/views/Pages/ContentSection/PatientPortal");
const OnlineSeminar = () => import ('@/views/Pages/ContentSection/OnlineSeminar');

const Doctors = () => import(/* webpackChunkName: "doctors" */ '@/views/Pages/ContentSection/OurTeam/Doctors.vue');
const ShowDoctor = () => import(/* webpackChunkName: "doctors" */ '@/views/Pages/ContentSection/OurTeam/ShowDoctor');
const EditDoctor = () => import(/* webpackChunkName: "doctors" */ '@/views/Pages/ContentSection/OurTeam/EditDoctor');
const StoreDoctor = () => import(/* webpackChunkName: "doctors" */ '@/views/Pages/ContentSection/OurTeam/StoreDoctor');

const ProgramLogo = () => import  (/* webpackChunkName: "programLogo" */ "@/views/Pages/ContentSection/ProgramLogo");

const FaqList = () => import (/* webpackChunkName: "faqs" */ "@/views/Pages/Faqs/FaqList");
const FaqForm = () => import (/* webpackChunkName: "faqs" */ "@/views/Pages/Faqs/FaqForm");
const FaqDetails = () => import (/* webpackChunkName: "faqs" */ "@/views/Pages/Faqs/FaqDetails");

const ResourceList = () => import (/* webpackChunkName: "resourceManager" */ "@/views/Pages/ContentSection/ResourceList");

const Diet = () => import(/* webpackChunkName: "diet" */ '@/views/Pages/ContentSection/Diet/Diet/Diet.vue');
const ShowDiet = () => import(/* webpackChunkName: "diet" */ '@/views/Pages/ContentSection/Diet/Diet/ShowDiet');
const EditDiet = () => import(/* webpackChunkName: "diet" */ '@/views/Pages/ContentSection/Diet/Diet/EditDiet');
const StoreDiet = () => import(/* webpackChunkName: "diet" */ '@/views/Pages/ContentSection/Diet/Diet/StoreDiet');
const DietCategories = () => import(/* webpackChunkName: "DietCategories" */ '@/views/Pages/ContentSection/Diet/DietCategories/DietCategories.vue');

const DietSubCategories = () => import(/* webpackChunkName: "DietSubCategories" */ '@/views/Pages/ContentSection/Diet/DietSubCategories/DietSubCategories.vue');

//Member Portals || Patient Portals
const MemberPortal = () => import(/* webpackChunkName: "diet" */ '@/views/Pages/ContentSection/MemberPortal/MemberPortal');
const ShowMemberPortal = () => import(/* webpackChunkName: "diet" */ '@/views/Pages/ContentSection/MemberPortal/ShowMemberPortal');
const StoreMemberPortal = () => import(/* webpackChunkName: "diet" */ '@/views/Pages/ContentSection/MemberPortal/StoreMemberPortal');
const EditMemberPortal = () => import(/* webpackChunkName: "diet" */ '@/views/Pages/ContentSection/MemberPortal/EditMemberPortal');

//Instruction
const Instructions = () => import(/* webpackChunkName: "instructions" */ '@/views/Pages/ContentSection/Instructions/Instructions/Instructions.vue');
const ShowInstruction = () => import(/* webpackChunkName: "instructions" */ '@/views/Pages/ContentSection/Instructions/Instructions/ShowInstruction');
const EditInstruction = () => import(/* webpackChunkName: "instructions" */ '@/views/Pages/ContentSection/Instructions/Instructions/EditInstruction');
const StoreInstruction = () => import(/* webpackChunkName: "instructions" */ '@/views/Pages/ContentSection/Instructions/Instructions/StoreInstruction');

const InstructionCategories = () => import(/* webpackChunkName: "InstructionCategories" */ '@/views/Pages/ContentSection/Instructions/InstructionCategories/InstructionCategories.vue');

const InstructionSubCategories = () => import(/* webpackChunkName: "InstructionSubCategories" */ '@/views/Pages/ContentSection/Instructions/InstructionSubCategories/InstructionSubCategories.vue');

// Vitamin and Supplements
const VitaminSupplement = () => import(/* webpackChunkName: "VitaminSupplement" */ '@/views/Pages/ContentSection/VitaminSupplement/VitaminSupplement/VitaminSupplement.vue')
const ShowVitaminSupplement = () => import(/* webpackChunkName: "VitaminSupplement" */ '@/views/Pages/ContentSection/VitaminSupplement/VitaminSupplement/ShowVitaminSupplement.vue')
const EditVitaminSupplement = () => import(/* webpackChunkName: "VitaminSupplement" */ '@/views/Pages/ContentSection/VitaminSupplement/VitaminSupplement/EditVitaminSupplement.vue')
const StoreVitaminSupplement = () => import(/* webpackChunkName: "VitaminSupplement" */ '@/views/Pages/ContentSection/VitaminSupplement/VitaminSupplement/StoreVitaminSupplement.vue')

const VitaminSupplementCategory = () => import(/* webpackChunkName: "VitaminSupplementCategory" */ '@/views/Pages/ContentSection/VitaminSupplement/VitaminSupplementCategory/VitaminSupplementCategories.vue')
const VitaminSupplementSubCategory = () => import(/* webpackChunkName: "VitaminSupplementCategory" */ '@/views/Pages/ContentSection/VitaminSupplement/VitaminSupplementSubCategory/VitaminSupplementSubCategories.vue')

//NonSurgicalWeightLoss
const NonSurgicalWeightLoss = () => import(/* webpackChunkName: "NonSurgicalWeightLoss" */ '@/views/Pages/ContentSection/NonSurgicalWeightLoss/NonSurgicalWeightLoss/NonSurgicalWeightLoss.vue');
const ShowNonSurgicalWeightLoss = () => import(/* webpackChunkName: "NonSurgicalWeightLoss" */ '@/views/Pages/ContentSection/NonSurgicalWeightLoss/NonSurgicalWeightLoss/ShowNonSurgicalWeightLoss');
const EditNonSurgicalWeightLoss = () => import(/* webpackChunkName: "NonSurgicalWeightLoss" */ '@/views/Pages/ContentSection/NonSurgicalWeightLoss/NonSurgicalWeightLoss/EditNonSurgicalWeightLoss');
const StoreNonSurgicalWeightLoss = () => import(/* webpackChunkName: "NonSurgicalWeightLoss" */ '@/views/Pages/ContentSection/NonSurgicalWeightLoss/NonSurgicalWeightLoss/StoreNonSurgicalWeightLoss');

const NonSurgicalWeightLossCategories = () => import(/* webpackChunkName: "NonSurgicalWeightLossCategories" */ '@/views/Pages/ContentSection/NonSurgicalWeightLoss/NonSurgicalWeightLossCategories/NonSurgicalWeightLossCategories.vue');

const NonSurgicalWeightLossSubCategories = () => import(/* webpackChunkName: "NonSurgicalWeightLossSubCategories" */ '@/views/Pages/ContentSection/NonSurgicalWeightLoss/NonSurgicalWeightLossSubCategories/NonSurgicalWeightLossSubCategories.vue');


const Surgery = () => import(/* webpackChunkName: "surgery" */ '@/views/Pages/ContentSection/Surgery/Surgery/Surgery.vue');
const ShowSurgery = () => import(/* webpackChunkName: "surgery" */ '@/views/Pages/ContentSection/Surgery/Surgery/ShowSurgery');
const EditSurgery = () => import(/* webpackChunkName: "surgery" */ '@/views/Pages/ContentSection/Surgery/Surgery/EditSurgery');
const StoreSurgery = () => import(/* webpackChunkName: "surgery" */ '@/views/Pages/ContentSection/Surgery/Surgery/StoreSurgery');

const SurgeryCategories = () => import(/* webpackChunkName: "SurgeryCategories" */ '@/views/Pages/ContentSection/Surgery/SurgeryCategories/SurgeryCategories.vue');

const SurgerySubCategories = () => import(/* webpackChunkName: "SurgerySubCategories" */ '@/views/Pages/ContentSection/Surgery/SurgerySubCategories/SurgerySubCategories.vue');

const ChangePassword = () => import(/* webpackChunkName: "ChangePassword" */ '@/views/Pages/User/ChangePassword.vue');

const ManageMenu = () => import(/* webpackChunkName: "ManageMenu" */ '@/views/Pages/ManageMenu/ManageMenu.vue');

const Videos = () => import(/* webpackChunkName: "Videos" */ '@/views/Pages/ContentSection/Videos/Videos.vue');
const SuccessStories = () => import(/* webpackChunkName: "SuccessStories" */ '@/views/Pages/ContentSection/SuccessStories/SuccessStories.vue');
const ShowSuccessStory = () => import(/* webpackChunkName: "SuccessStories" */ '@/views/Pages/ContentSection/SuccessStories/ShowSuccessStory.vue')
const EditSuccessStory = () => import(/* webpackChunkName: "SuccessStories" */ '@/views/Pages/ContentSection/SuccessStories/EditSuccessStory.vue')
const StoreSuccessStory = () => import(/* webpackChunkName: "SuccessStories" */ '@/views/Pages/ContentSection/SuccessStories/StoreSuccessStory.vue')

const SupportService = () => import(/* webpackChunkName: "SupportService" */ '@/views/Pages/ContentSection/SupportService/SupportService/SupportService.vue');
const ShowSupportService = () => import(/* webpackChunkName: "SupportService" */ '@/views/Pages/ContentSection/SupportService/SupportService/ShowSupportService.vue');
const StoreSupportService = () => import(/* webpackChunkName: "SupportService" */ '@/views/Pages/ContentSection/SupportService/SupportService/StoreSupportService.vue');
const EditSupportService = () => import(/* webpackChunkName: "SupportService" */ '@/views/Pages/ContentSection/SupportService/SupportService/EditSupportService.vue');

const SupportServiceCategory = () => import(/* webpackChunkName: "SupportServiceCategory" */ '@/views/Pages/ContentSection/SupportService/SupportServiceCategory/SupportServiceCategory.vue');

const SupportServiceSubCategory = () => import(/* webpackChunkName: "SupportServiceSubCategory" */ '@/views/Pages/ContentSection/SupportService/SupportServiceSubCategory/SupportServiceSubCategory.vue');

const Blog = () => import('@/views/Pages/ContentSection/Blog')

const Education  = () => import('@/views/Pages/ContentSection/Education')

const CheckMyBMI = () => import('@/views/Pages/ContentSection/CheckMyBMI')

const Diabetes = () => import('@/views/Pages/ContentSection/Diabetes')

const Shop = () => import('@/views/Pages/ContentSection/Shop')

const EditShop = () => import('@/views/Pages/ContentSection/EditShop')

const Newsletter = () => import('@/views/Pages/ContentSection/Newsletter')

const SupportGroup = () => import(/* webpackChunkName: "SupportGroup" */ '@/views/Pages/ContentSection/SupportGroup/SupportGroup/SupportGroup')
const ShowSupportGroup = () => import(/* webpackChunkName: "SupportGroup" */ '@/views/Pages/ContentSection/SupportGroup/SupportGroup/ShowSupportGroup')
const StoreSupportGroup = () => import(/* webpackChunkName: "SupportGroup" */ '@/views/Pages/ContentSection/SupportGroup/SupportGroup/StoreSupportGroup')
const EditSupportGroup = () => import(/* webpackChunkName: "SupportGroup" */ '@/views/Pages/ContentSection/SupportGroup/SupportGroup/EditSupportGroup')

const SupportGroupCategory = () => import('@/views/Pages/ContentSection/SupportGroup/SupportGroupCategory/SupportGroupCategory');

const SupportGroupSubCategory = () => import('@/views/Pages/ContentSection/SupportGroup/SupportGroupSubCategory/SupportGroupSubCategory');

//Surgery Date Reports
const PostOpPatientsReport = () => import('@/views/Pages/Seminar2Surgery/PostOpPatients');
const SurgeryDatePatientsReport = () => import('@/views/Pages/Seminar2Surgery/SurgeryDatePatients');

const NotConnectedPatientsReport = () => import('@/views/Pages/Seminar2Surgery/NotConnectedPatients');
const EventsRSVPReport = () => import('@/views/Pages/Seminar2Surgery/EventsRSVP/EventsRSVP');
const EventsListForRSVP = () => import('@/views/Pages/Seminar2Surgery/EventsRSVP/EventsListForRSVP');

const ContentCategories = () => import(/* webpackChunkName: "SurgeryCategories" */ '@/views/Pages/ContentSection/ContentCategories/ContentCategories.vue');
const ContentSubCategories = () => import(/* webpackChunkName: "SurgeryCategories" */ '@/views/Pages/ContentSection/ContentSubCategories/ContentSubCategories.vue');

const DynamicContentSection = () => import('@/views/Pages/ContentSection/DynamicContentSection/DynamicContentSection/DynamicContentSection');
const ShowDynamicContentSection = () =>import('@/views/Pages/ContentSection/DynamicContentSection/DynamicContentSection/ShowDynamicContentSection');
const StoreDynamicContentSectionItem = () =>import('@/views/Pages/ContentSection/DynamicContentSection/DynamicContentSection/StoreDynamicContentSectionItem');
const EditDynamicContentSectionItem = () =>import(/* webpackChunkName: "diet" */ '@/views/Pages/ContentSection/DynamicContentSection/DynamicContentSection/EditDynamicContentSectionItem');

const DynamicContentSectionCategory = () => import('@/views/Pages/ContentSection/DynamicContentSection/DynamicContenSectionCategory/DynamicContentSectionCategory')
const DynamicContentSectionSubCategory = () => import('@/views/Pages/ContentSection/DynamicContentSection/DynamicContentSectionSubCategory/DynamicContentSectionSubCategory')

//Help (Support Messages) Routes
// const SupportMessages = () => import('@/views/Pages/SupportMessage/SupportMessages') // Removed as per client suggestion.
const StoreSupportMessage = () => import('@/views/Pages/SupportMessage/StoreSupportMessage')

let componentsMenu = {
  path: '/components',
  component: DashboardLayout,
  redirect: '/components/buttons',
  name: 'Components',
  meta: { auth: true , super_admin: false},
  children: [
    {
      path: 'buttons',
      name: 'Buttons',
      component: Buttons,
    },
    {
      path: 'cards',
      name: 'Cards',
      component: Cards
    },
    {
      path: 'grid-system',
      name: 'Grid System',
      component: GridSystem
    },
    {
      path: 'notifications',
      name: 'Notifications',
      component: Notifications
    },
    {
      path: 'icons',
      name: 'Icons',
      component: Icons
    },
    {
      path: 'typography',
      name: 'Typography',
      component: Typography
    }
  ]
};

let formsMenu = {
  path: '/forms',
  component: DashboardLayout,
  redirect: '/forms/elements',
  name: 'Forms',
  meta: { auth: true , super_admin: false},
  children: [
    {
      path: 'elements',
      name: 'Form elements',
      component: FormElements
    },
    {
      path: 'components',
      name: 'Form components',
      component: FormComponents
    },
    {
      path: 'validation',
      name: 'Form validation',
      component: FormValidation
    }
  ]
};

let tablesMenu = {
  path: '/tables',
  component: DashboardLayout,
  redirect: '/table/regular',
  name: 'Tables menu',
  meta: { auth: true , super_admin: false},
  children: [
    {
      path: 'regular',
      name: 'Tables',
      component: RegularTables
    },
    {
      path: 'sortable',
      name: 'Sortable',
      component: SortableTables
    },
    {
      path: 'paginated',
      name: 'Paginated Tables',
      component: PaginatedTables
    }
  ]
};

let mapsMenu = {
  path: '/maps',
  component: DashboardLayout,
  name: 'Maps',
  redirect: '/maps/google',
  meta: { auth: true , super_admin: false},
  children: [
    {
      path: 'google',
      name: 'Google Maps',
      component: GoogleMaps
    },
    {
      path: 'vector',
      name: 'Vector Map',
      component: VectorMaps
    }
  ]
};

let pagesMenu = {
  path: '/pages',
  component: DashboardLayout,
  name: 'Pages',
  redirect: '/pages/user',
  meta: { auth: true , super_admin: false},
  children: [
    {
      path: 'user',
      name: 'User Page',
      component: User
    },
    {
      path: 'timeline',
      name: 'Timeline Page',
      component: TimeLine
    }
  ]
};

const routes = [
  {
    path: '/',
    component: DashboardLayout,
    redirect: '/dashboard',
    name: 'Dashboard layout',
    meta: { auth: true , super_admin: false},
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: Dashboard
      },
      {
        path: 'manage-patients',
        name: 'ManagePatients',
        component: ManagePatients,
        beforeEnter: (to, from, next) => {
          checkSelfServiceAccount(to, from, next)
        }
      },
      {
        path: 'checklist-templates',
        name: 'ChecklistTemplates',
        component: ChecklistTemplates,
        beforeEnter: (to, from, next) => {
          checkSelfServiceAccount(to, from, next)
        }
      },
      {
        path: 'patient-detail/:id',
        name: 'PatientDetail',
        component: PatientDetail,
        beforeEnter: (to, from, next) => {
          checkSelfServiceAccount(to, from, next)
        }
      },
      {
        path: 'patient-detail/:id/edit',
        name: 'EditPatientDetail',
        component: EditPatientDetail,
        beforeEnter: (to, from, next) => {
          checkSelfServiceAccount(to, from, next)
        }
      },
      {
        path: 'add-patient',
        name: 'AddPatient',
        component: AddPatient,
        beforeEnter: (to, from, next) => {
          checkSelfServiceAccount(to, from, next)
        }
      },
      {
        path: 'pipeline',
        name: 'Pipeline',
        component: PipelinePatients,
        beforeEnter: (to, from, next) => {
          checkSelfServiceAccount(to, from, next)
        }
      },
      {
        path: 'nutrition-report/:id',
        name: 'NutritionReport',
        component: NutritionReport,
        beforeEnter: (to, from, next) => {
          checkSelfServiceAccount(to, from, next)
        }
      },
      {
        path: 'weight-log/:id',
        name: 'WeightLog',
        component: WeightLog,
        beforeEnter: (to, from, next) => {
          checkSelfServiceAccount(to, from, next)
        }
      },
      {
        path: 'message-templates',
        name: 'Message Templates',
        component: MessageTemplate,
        beforeEnter: (to, from, next) => {
          checkSelfServiceAccount(to, from, next)
        }
      },
      {
        path: 'alerts',
        name: 'NutritionAlerts',
        component: NutritionAlerts,
        beforeEnter: (to, from, next) => {
          checkSelfServiceAccount(to, from, next)
        }
      },
      {
        path: 'automatic-messages',
        name: 'AutomaticMessages',
        component: AutomaticMessages
      },
      {
        path: 'user-category',
        name: 'UserCategoryList',
        component: UserCategory,
        beforeEnter: (to, from, next) => {
          checkSelfServiceAccount(to, from, next)
        }
      },
      {
        path: 'category-form',
        name: 'CategoryForm',
        component: CategoryForm,
        props: true,
        beforeEnter: (to, from, next) => {
          checkSelfServiceAccount(to, from, next)
        }
      },
      {
        path: 'food-log/:id',
        name: 'FoodLog',
        component: FoodLog,
        beforeEnter: (to, from, next) => {
          checkSelfServiceAccount(to, from, next)
        }
      },
      {
        path: 'checklist-template/:id/store',
        name: 'StoreChecklistTemplate',
        component: StoreChecklistTemplate,
        beforeEnter: (to, from, next) => {
          checkSelfServiceAccount(to, from, next)
        }
      },
      {
        path: 'checklist-template/:id/edit/:rowId',
        name: 'EditChecklistTemplate',
        component: EditChecklistTemplate,
        beforeEnter: (to, from, next) => {
          checkSelfServiceAccount(to, from, next)
        }
      },
      {
        path: 'checklist-template/:id/copy/:rowId',
        name: 'CopyChecklistTemplate',
        component: CopyChecklistTemplate,
        beforeEnter: (to, from, next) => {
          checkSelfServiceAccount(to, from, next)
        }
      },
      {
        path: 'customize-pipeline',
        name: 'CustomizePipeline',
        component: CustomizePipeline,
        beforeEnter: (to, from, next) => {
          checkSelfServiceAccount(to, from, next)
        }
      },
      {
        path: 'charts',
        name: 'Charts',
        component: Charts,
        beforeEnter: (to, from, next) => {
          checkSelfServiceAccount(to, from, next)
        }
      },
      {
        path: 'widgets',
        name: 'Widgets',
        component: Widgets,
        beforeEnter: (to, from, next) => {
          checkSelfServiceAccount(to, from, next)
        }
      },
      {
        path: 'calendar',
        name: 'Calendar',
        component: Calendar,
        beforeEnter: (to, from, next) => {
          checkSelfServiceAccount(to, from, next)
        }
      },
      {
        path: 'add-insurance-coordinator',
        name: 'add-insurance-coordinator',
        component: AddInsuranceCoordinator,
        beforeEnter: (to, from, next) => {
          checkSelfServiceAccount(to, from, next)
        }
      },
      {
        path: 'insurance-coordinator/:id/edit',
        name: 'edit-insurance-coordinator',
        component: EditInsuranceCoordinator,
        beforeEnter: (to, from, next) => {
          checkSelfServiceAccount(to, from, next)
        }
      },
      {
        path: 'insurance-coordinator/:id/show',
        name: 'show-insurance-coordinator',
        component: ShowInsuranceCoordinator,
        beforeEnter: (to, from, next) => {
          checkSelfServiceAccount(to, from, next)
        }
      },
      {
        path: 'checklists/:id',
        name: 'checklists',
        component: Checklist,
        beforeEnter: (to, from, next) => {
          checkSelfServiceAccount(to, from, next)
        }
      },
      {
        path: 'checklist-items/:tempStage',
        name: 'ChecklistItems',
        component: ChecklistItems,
        beforeEnter: (to, from, next) => {
          checkSelfServiceAccount(to, from, next)
        }
      },
      {
        path: 'seminar2surgery',
        name: 'Seminar2Surgery',
        component: Seminar2Surgery,
        beforeEnter: (to, from, next) => {
          checkSelfServiceAccount(to, from, next)
        }
      },
      {
        path: 'scheduled-messages',
        name: 'ScheduledMessages',
        component: ScheduledMessages,
      },
      {
        path: 'scheduled-message/:id/show',
        name: 'ShowScheduledMessage',
        component: ShowScheduledMessage
      },
      {
        path: 'on-demand-messages',
        name: 'OnDemand Messages',
        component: OnDemandMessages
      },
      {
        path: 'on-demand-messages/:id/show',
        name: 'ShowOnDemandMessage',
        component: ShowOnDemandMessage
      },
      {
        path: 'event-calender',
        name: 'EventList',
        component: EventList,
      },
      {
        path: 'event-calender/form',
        name: 'EventFormNew',
        props: true,
        component: EventForm,
      },
      {
        path: 'event-calender/form/:id?',
        name: 'EventFormEdit',
        props: false,
        component: EventForm,
      },
      {
        path: 'event-calender/view/:id',
        name: 'EventDetail',
        props: true,
        component: EventDetail
      },
      {
        path: 'event-calender/locations',
        name: 'Event Locations',
        component: EventLocationList
      },
      {
        path: 'contacts',
        name: 'Contacts',
        component: Contacts
      },
      {
        path: 'contact-us',
        name: 'Contacts',
        component: Contacts
      },
      {
        path: 'contacts/:id/show',
        name: 'ShowContact',
        component:ShowContact
      },
      {
        path: 'contacts/:id/edit',
        name: 'EditContact',
        component:EditContact
      },
      {
        path: 'contacts/store',
        name: 'StoreContact',
        component:StoreContact
      },
      {
        path: 'our-website',
        name: 'OurWebsite',
        component: OurWebsite
      },
      {
        path: 'join-our-facebook',
        name: 'joinFacebook',
        component: JoinUsOnFacebook
      },
      {
        path: 'patient-portal',
        name: 'PatientPortal',
        component: PatientPortal
      },
      {
        path: 'program-logo',
        name: 'Program Logo',
        component: ProgramLogo
      },
      {
        path: 'online-seminar',
        name: 'Online Seminar',
        component: OnlineSeminar
      },
      {
        path: 'coordinator-categories',
        name: 'CoordinatorCategories',
        component:CoordinatorCategories
      },
      {
        path: 'coordinator-categories-1',
        name: 'CoordinatorSubCategories',
        component:CoordinatorSubCategories
      },
      {
        path: 'coordinator-categories-2',
        name: 'CoordinatorLastCategories',
        component:CoordinatorLastCategories
      },
      {
        path: 'doctors',
        name: 'Doctors',
        component: Doctors
      },
      {
        path: 'doctors/:id/show',
        name: 'ShowDoctor',
        component:ShowDoctor
      },
      {
        path: 'doctors/:id/edit',
        name: 'EditDoctor',
        component:EditDoctor
      },
      {
        path: 'doctors/store',
        name: 'StoreDoctor',
        component:StoreDoctor
      },
      {
        path: 'faqs',
        name: 'FaqList',
        component: FaqList
      },
      {
        path: 'faq/new',
        name: 'NewFaq',
        props: true,
        component: FaqForm
      },
      {
        path: 'faq/:id/edit',
        name: 'EditFaq',
        component: FaqForm
      },
      {
        path: 'faq/:id/show',
        name: 'FaqDetails',
        props: true,
        component: FaqDetails
      },
      {
        path: 'resource-manager',
        name: 'ResourceList',
        component: ResourceList
      },
      {
        path: 'diet',
        name: 'Diet',
        component: Diet
      },
      {
        path: 'diet/:id/show',
        name: 'ShowDiet',
        component:ShowDiet
      },
      {
        path: 'diet/:id/edit',
        name: 'EditDiet',
        component:EditDiet
      },
      {
        path: 'diet/store',
        name: 'StoreDiet',
        component:StoreDiet
      },
      {
        path: 'diet-categories',
        name: 'DietCategories',
        component:DietCategories
      },
      {
        path: 'diet-sub-categories',
        name: 'DietSubCategories',
        component:DietSubCategories
      },
      {
        path: 'patient-portals',
        name: 'PatientPortals',
        component: MemberPortal
      },
      {
        path: 'patient-portals/:id/show',
        name: 'ShowPatientPortal',
        component: ShowMemberPortal
      },
      {
        path: 'patient-portals/store',
        name: 'StoreMemberPortal',
        component:StoreMemberPortal
      },
      {
        path: 'patient-portals/:id/edit',
        name: 'EditMemberPortal',
        component:EditMemberPortal
      },
      {
        path: 'instructions',
        name: 'Instructions',
        component: Instructions
      },
      {
        path: 'instructions/store',
        name: 'StoreInstruction',
        component:StoreInstruction
      },
      {
        path: 'instructions/:id/show',
        name: 'ShowInstruction',
        component:ShowInstruction
      },
      {
        path: 'instructions/:id/edit',
        name: 'EditInstruction',
        component:EditInstruction
      },
      {
        path: 'instruction-categories',
        name: 'InstructionCategories',
        component:InstructionCategories
      },
      {
        path: 'instruction-sub-categories',
        name: 'InstructionSubCategories',
        component:InstructionSubCategories
      },
      {
        path: '/vitamin-supplement',
        name: 'VitaminSupplement',
        component: VitaminSupplement
      },
      {
        path: 'vitamin-supplement/store',
        name: 'StoreVitaminSupplement',
        component:StoreVitaminSupplement
      },
      {
        path: 'vitamin-supplement/:id/edit',
        name: 'EditVitaminSupplement',
        component:EditVitaminSupplement
      },
      {
        path: 'vitamin-supplement/:id/show',
        name: 'ShowVitaminSupplement',
        component:ShowVitaminSupplement
      },
      {
        path: 'vitamin-supplement/category',
        name: 'VitaminSupplementCategory',
        component: VitaminSupplementCategory
      },
      {
        path: 'vitamin-supplement/sub-category',
        name: 'VitaminSupplementSubCategory',
        component: VitaminSupplementSubCategory
      },
      {
        path: 'non-surgical-weight-loss',
        name: 'Non Surgical Weight Loss',
        component: NonSurgicalWeightLoss
      },
      {
        path: 'non-surgical-weight-loss/store',
        name: 'StoreNonSurgicalWeightLoss',
        component:StoreNonSurgicalWeightLoss
      },
      {
        path: 'non-surgical-weight-loss/:id/show',
        name: 'ShowNonSurgicalWeightLoss',
        component:ShowNonSurgicalWeightLoss
      },
      {
        path: 'non-surgical-weight-loss/:id/edit',
        name: 'EditNonSurgicalWeightLoss',
        component:EditNonSurgicalWeightLoss
      },
      {
        path: 'non-surgical-weight-loss-categories',
        name: 'NonSurgicalWeightLossCategories',
        component:NonSurgicalWeightLossCategories
      },
      {
        path: 'non-surgical-weight-loss-sub-categories',
        name: 'NonSurgicalWeightLossSubCategories',
        component:NonSurgicalWeightLossSubCategories
      },
      {
        path: 'about-surgery',
        name: 'About Surgery',
        component: Surgery
      },
      {
        path: 'surgery/:id/show',
        name: 'ShowSurgery',
        component:ShowSurgery
      },
      {
        path: 'surgery/:id/edit',
        name: 'EditSurgery',
        component:EditSurgery
      },
      {
        path: 'surgery/store',
        name: 'StoreSurgery',
        component:StoreSurgery
      },
      {
        path: 'surgery-categories',
        name: 'SurgeryCategories',
        component:SurgeryCategories
      },
      {
        path: 'surgery-sub-categories',
        name: 'SurgerySubCategories',
        component:SurgerySubCategories
      },
      {
        path: 'change-password',
        name: 'ChangePassword',
        component:ChangePassword
      },
      {
        path: 'manage-menu',
        name: 'ManageMenu',
        component:ManageMenu,
        beforeEnter: (to, from, next) => {
          checkSelfServiceAccount(to, from, next)
        }
      },
      {
        path: 'success-stories',
        name: 'SuccessStories',
        component:SuccessStories
      },
      {
        path: 'success-stories/:id/show',
        name: 'ShowSuccessStory',
        component: ShowSuccessStory
      },
      {
        path: 'success-stories/:id/edit',
        name: 'EditSuccessStory',
        component: EditSuccessStory
      },
      {
        path: 'success-stories/store',
        name: 'StoreSuccessStory',
        component: StoreSuccessStory
      },
      {
        path: 'support-service',
        name: 'SupportService',
        component: SupportService
      },
      {
        path: 'support-service/:id/show',
        name: 'ShowSupportService',
        component: ShowSupportService
      },
      {
        path: 'support-service/:id/edit',
        name: 'EditSupportService',
        component: EditSupportService
      },
      {
        path: 'support-service/store',
        name: 'StoreSupportService',
        component: StoreSupportService
      },
      {
        path: 'support-service-category',
        name: 'SupportServiceCategory',
        component: SupportServiceCategory
      },
      {
        path: 'support-service-sub-category',
        name: 'SupportServiceSubCategory',
        component: SupportServiceSubCategory
      },
      {
        path: 'videos',
        name: 'Videos',
        component: Videos
      },
      {
        path: 'blog',
        name: 'Blog',
        component: Blog
      },
      {
        path: 'education',
        name: 'Education',
        component: Education
      },
      {
        path: 'check-my-bmi',
        name: 'CheckMyBMI',
        component: CheckMyBMI
      },
      {
        path: 'diabetes',
        name: 'Diabetes',
        component: Diabetes
      },
      {
        path: 'shop',
        name: 'Shop',
        component: Shop
      },
      {
        path: 'shop/edit',
        name: 'EditShop',
        component: EditShop
      },
      {
        path: 'newsletter',
        name: 'Newsletter',
        component: Newsletter
      },
      {
        path: 'support-group',
        name: 'SupportGroup',
        component: SupportGroup
      },
      {
        path: 'support-group/:id/show',
        name: 'ShowSupportGroup',
        component: ShowSupportGroup
      },
      {
        path: 'support-group/store',
        name: 'StoreSupportGroup',
        component: StoreSupportGroup
      },
      {
        path: 'support-group/:id/edit',
        name: 'EditSupportGroup',
        component: EditSupportGroup
      },
      {
        path: 'support-group-category',
        name: 'SupportGroupCategory',
        component: SupportGroupCategory
      },
      {
        path: 'support-group-sub-category',
        name: 'SupportGroupSubCategory',
        component: SupportGroupSubCategory
      },
      {
        path: 'post-op-patients-report',
        name: 'PostOpPatientsReport',
        component: PostOpPatientsReport,
        beforeEnter: (to, from, next) => {
          checkSelfServiceAccount(to, from, next)
        }
      },
      {
        path: 'surgery-date-patients-report',
        name: 'SurgeryDatePatientsReport',
        component: SurgeryDatePatientsReport,
        beforeEnter: (to, from, next) => {
          checkSelfServiceAccount(to, from, next)
        }
      },
      {
        path: 'not-connected-patients-report',
        name: 'NotConnectedPatientsReport',
        component: NotConnectedPatientsReport,
        beforeEnter: (to, from, next) => {
          checkSelfServiceAccount(to, from, next)
        }
      },
      {
        path: 'events-rsvp-list',
        name: 'EventsRSVPList',
        component: EventsListForRSVP,
        beforeEnter: (to, from, next) => {
          checkSelfServiceAccount(to, from, next)
        }
      },
      {
        path: 'events-rsvp-report/:eventId/:eventDate',
        name: 'EventsRSVPReport',
        component: EventsRSVPReport,
        beforeEnter: (to, from, next) => {
          checkSelfServiceAccount(to, from, next)
        }
      },
      {
        path: ':menuTitle/categories',
        name: 'ContentCategories',
        component:ContentCategories,
      },
      {
        path: ':menuTitle/sub-categories',
        name: 'ContentSubCategories',
        component:ContentSubCategories,
      },
      {
        path: 'patient-pipeline-report',
        name: 'PatientsPipelineReport',
        component: PipelineReport,
        beforeEnter: (to, from, next) => {
          checkSelfServiceAccount(to, from, next)
        }
      },
      {
        path: 'dynamic-content-section/:id',
        name: 'DynamicContentSection',
        component: DynamicContentSection
      },
      {
        path: 'dynamic-section-item/:id/show',
        name: 'ShowDynamicContentSection',
        component: ShowDynamicContentSection
      },
      {
        path: 'dynamic-section-item/store/:sectionId',
        name: 'StoreDynamicContentSectionItem',
        component: StoreDynamicContentSectionItem
      },
      {
        path: 'dynamic-section-item/:sectionId/edit/:itemId',
        name: 'EditDynamicContentSectionItem',
        component: EditDynamicContentSectionItem
      },
      {
        path: 'dynamic-section-category/:sectionId',
        name: 'DynamicContentSectionCategory',
        component: DynamicContentSectionCategory
      },
      {
        path: 'dynamic-section-sub-category/:sectionId',
        name: 'DynamicContentSectionSubCategory',
        component: DynamicContentSectionSubCategory
      },
      {
        path: 'support-messages/:type/store',
        name: 'StoreSupportMessage',
        component: StoreSupportMessage
      },
    ],
    beforeEnter: (to, from, next) => {
      // check if token exists.
      if (to.name == 'CustomizePipeline') {
        let flag = window.localStorage.getItem('customizePipeline');
        if(!flag){
          next({name: 'Pipeline'});
        }
      }
      if (authHelper.getAuthToken('subUser') === false) {
        authHelper.deleteManagePatientsSavedSearch()
        authHelper.deleteEventCalendarSavedSearch()
        next({name: 'Login'});
      }
      else if(authHelper.getAuthToken('subUser') &&  (authHelper.getIsPasswordChanged() == 0 && store.getters.getIsPasswordChangePage == 0)){
        store.commit('setIsPasswordChangePage',1);
        next({name: 'ChangePassword'});
      }
      else {
        store.commit('setIsPasswordChangePage',0);
        next();
      }
    },
  },
  {
    path: '/reset-patient-password',
    name: 'PatientResetPassword',
    component: ResetPassword,
    meta: { auth: true , super_admin: false},
  },
];

function checkSelfServiceAccount(to, from, next) {
  if (authHelper.getSelfServiceCheck() == 1) {
    return next({name: 'Dashboard'})
  } else {
    return next()
  }
}

export default routes;
