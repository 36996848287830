import axios from "axios";

export default {
  namespaced: true,
  state: {
    base_url: process.env.VUE_APP_BASE_API_URL,
    trophyCard: {
      title: '',
      user_tier: '',
      rule_type: 'food_log',
      date: '',
      days: 1,
      days_condition: 'in_row',
      surgery_day_type: 'day',
      surgery_days: 1,
      oldFrontImage: '',
      ad_front_image: '',
      oldBackImage: '',
      ad_back_image: '',
      link: '',
      notification_content: '',
    }
  },
  getters: {
    getCardTrophy(state) {
      return state.trophyCard
    }
  },
  actions: {
    /**
     * Reset Logout Time fields
     * @param commit
     * @private
     */
    _resetLogoutTimer({commit}) {
      commit('toggleSessionCheck', true, {root: true})
      commit('updateSessionTime', 0, {root: true})
    },

    /**
     * Store trophy card
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */
    _storeTrophyCard({state, dispatch}, {config, formData}) {
      return new Promise((resolve, reject) => {
        axios.post(`${state.base_url}/api/admin/card-trophies/store`, formData, {config})
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Get requested trophy card
     * @param context - Contains the commit and state object
     * @param params - Parameters for the API
     * @returns {Promise<unknown>}
     * @private
     */
    _getRequestedTrophyCard({state, dispatch}, params) {
      return new Promise((resolve, reject) => {
        axios.get(`${state.base_url}/api/admin/card-trophies/${params.id}`)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Store trophy card
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */
    _updateTrophyCard({state, dispatch}, {config, formData}) {
      return new Promise((resolve, reject) => {
        axios.post(`${state.base_url}/api/admin/card-trophies/update`, formData, {config})
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Get all card trophies
     * @param context - Contains the commit and state object
     * @returns {Promise<unknown>}
     * @private
     */
    _getAllTrophyCards({state, dispatch}) {
      return new Promise((resolve, reject) => {
        axios.get(`${state.base_url}/api/admin/card-trophies`)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Update trophy card status
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * * @returns {Promise<unknown>}
     * @private
     */
    _updateStatusTrophyCard({state, dispatch}, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`${state.base_url}/api/admin/card-trophies/toggle-status`, payload)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          }).catch(error => {
          reject(error)
        })
      })
    },

    /**
     * Delete trophy card
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * * @returns {Promise<unknown>}
     * @private
     */
    _deleteTrophyCard({state, dispatch}, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`${state.base_url}/api/admin/card-trophies/delete`, payload)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          }).catch(error => {
          reject(error)
        })
      })
    },
  }
};
