import axios from "axios";

export default {
  namespaced: true,
  state: {
    base_url: process.env.VUE_APP_BASE_API_URL,
    location: {
      user_id: 48,
      name: '',
      address: '',
      latitude: 0,
      longitude: 0,
      status: 1,
    },
    addEditModal: false,
    sourceGetAll: '',
  },
  getters: {
    getLocation(state) {
      return state.location
    },

    getAddEditModalToggle(state) {
      return state.addEditModal
    }
  },
  mutations: {
    toggleAddEditModal(state, payload) {
      state.addEditModal = payload
    },
  },
  actions: {
    /**
     * Reset Logout Time fields
     * @param commit
     * @private
     */
    _resetLogoutTimer({commit}) {
      commit('toggleSessionCheck', true, {root: true})
      commit('updateSessionTime', 0, {root: true})
    },

    /**
     * Get Locations
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */
    _getLocations({state, dispatch}, {params} = null) {
      return new Promise((resolve, reject) => {
        if(state.sourceGetAll){
          state.sourceGetAll.cancel()
        }
        const CancelToken = axios.CancelToken;
        state.sourceGetAll = CancelToken.source();
        axios.get(`${state.base_url}/api/admin/event/location`, {params, cancelToken: state.sourceGetAll.token})
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Get Requested Location
     * @param context - Contains the commit and state object
     * @param params - Parameters for the API
     * @returns {Promise<unknown>}
     * @private
     */
    _getRequestedLocation({state, dispatch}, params) {
      return new Promise((resolve, reject) => {
        axios.get(`${state.base_url}/api/admin/event/location/${params.id}`)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Store Location
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */
    _storeLocation({state, dispatch}, {formData}) {
      return new Promise((resolve, reject) => {
        axios.post(`${state.base_url}/api/admin/event/location/store`, formData)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Update Location
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */
    _updateLocation({state, dispatch}, {formData}) {
      return new Promise((resolve, reject) => {
        axios.post(`${state.base_url}/api/admin/event/location/update`, formData)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Update Super Admin Timezone
     * @param commit
     * @param state
     * @param payload
     * @returns {Promise<unknown>}
     * @private
     */
    _updateTimezone({state, dispatch}, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`${state.base_url}/api/admin/event/location/timezone`, payload)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Update Location(s) status
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * * @returns {Promise<unknown>}
     * @private
     */
    _updateLocationStatus({state, dispatch}, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`${state.base_url}/api/admin/event/location/toggle-status`, payload)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          }).catch(error => {
          reject(error)
        })
      })
    },

    /**
     * Delete Location(s)
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * * @returns {Promise<unknown>}
     * @private
     */
    _deleteLocation({state, dispatch}, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`${state.base_url}/api/admin/event/location/delete`, payload)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          }).catch(error => {
          reject(error)
        })
      })
    },
  }
}
