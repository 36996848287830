import axios from "axios";

export default {
  namespaced: true,
  state: {
    base_url: process.env.VUE_APP_BASE_API_URL,
  },
  getters: {
    
  },
  actions: {
    
    /**
     * Reset Logout Time fields
     * @param commit
     * @private
     */
    _resetLogoutTimer({commit}) {
      commit('toggleSessionCheck', true, {root: true})
      commit('updateSessionTime', 0, {root: true})
    },
      /**
     * Get All Ad sets
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */
      _getAdSets({state, dispatch}, {params}) {
        return new Promise((resolve, reject) => {
          axios.get(`${state.base_url}/api/admin/banner-ads/ads-sets`,{params})
            .then(response => {
              dispatch('_resetLogoutTimer')
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },
    /**
     * Get Ad set by id
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */
    _getAdSetById({state, dispatch}, {id}) {
      return new Promise((resolve, reject) => {
        axios.get(`${state.base_url}/api/admin/banner-ads/ads-sets/${id}`)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    /**
     * Update Ad set 
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */
    _updateAdSet({state, dispatch}, {form,id}) {
      return new Promise((resolve, reject) => {
        axios.put(`${state.base_url}/api/admin/banner-ads/ads-sets/${id}`,form)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    /**
     * Store banner ad
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */
    _storeBannerAd({state, dispatch}, {fd,adSetId}) {
      return new Promise((resolve, reject) => {
        axios.post(`${state.base_url}/api/admin/banner-ads/store/${adSetId}`, fd, { headers: { 'content-type': 'multipart/form-data' } })
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    /**
     * Update banner ad
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */
    _updateBannerAd({state, dispatch}, {fd,id}) {
      return new Promise((resolve, reject) => {
        axios.post(`${state.base_url}/api/admin/banner-ads/update/${id}`, fd, { headers: { 'content-type': 'multipart/form-data' } })
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    /**
     * Delete banner ad
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */
    _deleteBannerAd({state, dispatch}, {id}) {
      return new Promise((resolve, reject) => {
        axios.delete(`${state.base_url}/api/admin/banner-ads/destroy/${id}`)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    /**
     * reset stats banner ad
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */
    _resetStatsBannerAd({state, dispatch}, {id}) {
      return new Promise((resolve, reject) => {
        axios.patch(`${state.base_url}/api/admin/banner-ads/reset-stats/${id}`)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    /**
     * toggle status banner ad
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */
      _toggleStatusBannerAd({state, dispatch}, {id}) {
      return new Promise((resolve, reject) => {
        axios.patch(`${state.base_url}/api/admin/banner-ads/toggle-status/${id}`)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    /**
     * Get Discount Ad
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */
    _getDiscountAd({state, dispatch}) {
      return new Promise((resolve, reject) => {
        axios.get(`${state.base_url}/api/admin/discount-ads`)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    /**
     * Delete Discount Ad
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */
    _deleteDiscountAd({state, dispatch}) {
      return new Promise((resolve, reject) => {
        axios.delete(`${state.base_url}/api/admin/discount-ads/destroy`)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    /**
     * reset stats discount ad
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */
    _resetStatsDiscountAd({state, dispatch}) {
      return new Promise((resolve, reject) => {
        axios.patch(`${state.base_url}/api/admin/discount-ads/reset-stats`)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    /**
     * toggle status discount ad
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */
    _toggleStatusDiscountAd({state, dispatch}) {
      return new Promise((resolve, reject) => {
        axios.patch(`${state.base_url}/api/admin/discount-ads/toggle-status`)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
        /**
     * Store or update discount ad
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */
    _storeOrUpdateDiscountAd({state, dispatch}, {fd}) {
      return new Promise((resolve, reject) => {
        axios.post(`${state.base_url}/api/admin/discount-ads/store`, fd)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    
  }
};
