<template>
  <validation-provider :rules="rules" :name="name" v-bind="$attrs" v-slot="{errors, valid, invalid,validated}" ref="validation_provider">
  <div class="form-group position-relative">
      <label for="" class="form-control-label" :class="{'is_required':labelRequired}" v-if="label">{{ label }}</label>
      <p class="clear-button-select position-absolute d-inline" v-if="customClearable" @click="()=>updateValue('')">Clear</p>
  <el-select
    v-on="listeners"
    v-bind="$attrs"
    :class="[{'is-valid': valid && validated && successMessage}, {'is-invalid': invalid && validated},{'custom-multiple':customMultiple}]"
    ref="select"
    >
    <el-option
      v-for="item in options"
      :key="item.value"
      :label="item.label"
      :value="item.value"
      :class="{'custom-multiple-dropdown':customMultiple}"
      >
      <template v-slot:default v-if="customMultiple">
        <div class="custom-multiple-option">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" :checked="isSelected(item.value)">
            <label class="form-check-label" for="inlineCheckbox1">{{ item.label }}</label>
          </div>
        </div>
      </template>
    </el-option>
  </el-select>
  <slot name="success">
    <div class="valid-feedback" v-if="valid && validated && successMessage">
      {{successMessage}}
    </div>
  </slot>
  <div v-if="errors[0]" class="invalid-feedback" style="display: block;">
        {{ errors[0] }}
  </div>
  </div>
</validation-provider>
</template>
<script>
  export default {
    name: 'base-select',
    inheritAttrs: false,
    props: {
      customClearable:{
        type: Boolean,
        default: false
      },
      label:{
        type:String,
        default:""
      },
      name:{
        type: String,
        default: "select"
      },
      options:{
        type: [Array, Object],
      },
      successMessage: {
        type: String,
        description: "Input success message",
        default: ''
      },
      rules: {
        type: [String, Array, Object],
        description: 'Vee validate validation rules',
        default: ''
      },
      labelRequired:{
        type: Boolean,
        default: false
      },
      customMultiple: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
      }
    },
    mounted(){
      this.$refs.validation_provider.reset();
    },
    computed: {
      listeners() {
        return {
          ...this.$listeners,
          change: this.updateValue,
          focus: this.onFocus,
          blur: this.onBlur
        };
      },

    },
    methods: {
      updateValue(value) {
        this.$emit("input", value);
        this.$emit("change", value);

      },
      onFocus(evt) {
        this.$emit("focus");
      },
      onBlur(evt) {
        this.$emit("blur");
      },
      isSelected(value){
        return this.$refs.validation_provider?.value?.includes(value);
      }

    }
  }
</script>
<style lang="scss">
.is_required::after{
  content: "*";
  color: red;
}
.clear-button-select{
    right: 0;
    top: 5px;
    font-size: 9px;
    font-weight: normal;
    color:#BB2255;
    cursor: pointer;
}
.el-select{
  &.is-valid{
    .el-input {
      &.is-focus {
        .el-input__inner{
          border-color: #2dce89 !important;
        }
        .el-input__icon{
          color: #2dce89 !important;
        }
      }
    }
    .el-input__inner{
      border-color: #2dce89 !important;
    }
    .el-input__icon{
      color: #2dce89 !important;
    }

  }
  &.is-invalid{
    .el-input {
      &.is-focus {
        .el-input__inner{
      border-color: #fb6340 !important;
    }
    .el-input__icon{
      color: #fb6340 !important;
    }
      }
    }
    .el-input__inner{
      border-color: #fb6340 !important;
    }
    .el-input__icon{
      color: #fb6340 !important;
    }
  }
}
</style>
