export default {
  average: (sum, total) => {
    return total === 0 ? 0 : sum / total;
  },

  isAlphaNumeric(characterString) {
    const letters = /^[0-9a-zA-Z]+$/;
    return (letters.test(characterString))
  },

  extractAndFormatDate(dateString, putComma=true){
    if (typeof dateString === 'string'){
      if (dateString.length >= 15){
        const date = dateString.substring(4,15)
        if (putComma)
          return date.substring(0,6)+','+date.substring(6)
        else return date
      }
    }
    return ''
  },

  /**
   * Formatting phone number in 999-999-9999
   * @param number
   * @returns {*|string}
   */
  formatPhoneNumber(number) {
    number = number.replace('+1', '')
    const x = number.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
    return !x[2] ? x[1] : x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : '')
  },

  addCustomStylingToContent(content) {
    if (content) {
      const customStyle = '<div style="font-family: Arial,sans-serif; font-size: 16px; line-height: 1.5;">'
      const customStyleWithoutWhiteSpace = customStyle.replace(/\s/g, "")
      const string = content.replace(/\s/g, "")

      if (string.includes(customStyleWithoutWhiteSpace) && string.indexOf(customStyleWithoutWhiteSpace) == 0) {
        return content
      } else {
        return customStyle + content + '</div>'
      }
    }
  },

  reverseString(str){
    return str.split("").reverse().join("");
  },

  addCommaToAmount(valueString){
    if (typeof valueString === 'string'){
      let i = 0;
      valueString = this.reverseString(valueString)
      for (i=3; i < valueString.length; i+=4) {
        valueString = valueString.substring(0,i)+','+valueString.substring(i)
      }
      valueString = this.reverseString(valueString)
      return valueString
    }else {
      return valueString
    }
  },

  async imageDimensionFromObjectFile(fileImage) {
    const url = URL.createObjectURL(fileImage)
    return this.loadImage(url)
  },

  loadImage: function(src) {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.onload = () => resolve(img)
      img.onerror = reject
      img.src = src
    })
  }


}
