import Vue from 'vue';
import moment from 'moment';

Vue.filter('globalDateFormat', function (date) {
  return moment(date).format('MMM D, YYYY');
})

Vue.filter('fullMonthWithYear', function (date) {
  return moment(date).format('MMMM YYYY');
});

Vue.filter('fullMonthName', function (date) {
  return moment(date).format('MMMM');
});

Vue.filter('fullYearName', function (date) {
  return moment(date).format('YYYY');
})

Vue.filter('time', function(date) {
  return moment.utc(date).local().format('hh:mma');
})
