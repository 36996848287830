import {Loading} from 'element-ui';

export default {
  install(Vue, options) {
    Vue.prototype.startLoading = () => {
      document.querySelector('#loader').classList.add('show-loader');
    },
      Vue.prototype.stopLoading = () => {
        let fullPageLoader = Loading.service({
          target: '#loader',
        });
        fullPageLoader.close();
        setTimeout(() => {
          document.querySelector('#loader').classList.remove('show-loader');
        }, 500)
      }
  }
};
