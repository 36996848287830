/*!

=========================================================
* Vue Argon Dashboard PRO - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import '@babel/polyfill'
import Vue from 'vue';
import DashboardPlugin from './plugins/dashboard-plugin';
import CustomPlugin from './plugins/custom-plugins';
import App from './App.vue';
// import './assets/css/tailwind.css'
import { store } from './vuex/store'
// router setup
import router from './routes/router';
import {Notification} from "element-ui";
import _ from 'lodash'

// stylesheet import
import '@/assets/css/globalStyles.css';
import '@/plugins/date-mixin.js';
import "flatpickr/dist/flatpickr.css";

// libraries
import Inputmask from 'inputmask'
Vue.use(Inputmask);

import VCalendar from 'v-calendar';
Vue.use(VCalendar, {
  componentPrefix: 'vc',  // Use <vc-calendar /> instead of <v-calendar />
});

//filters import
import '@/filters/moment';

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

// interceptors
import interceptorsSetup from '@/interceptors'
interceptorsSetup()

// plugin setup
Vue.use(DashboardPlugin);
Vue.use(CustomPlugin);
Vue.prototype.$notify = Notification;

Vue.directive('scroller', {
  inserted: function (el, binding) {
    let f = function (evt) {
      if (binding.value(evt, el)) {
        el.removeEventListener('scroll', f)
      }
    }
    el.addEventListener('scroll', f)
  }
})

Vue.directive('mask', {
  bind: function (el, binding) {
    Inputmask(binding.value).mask(el.getElementsByTagName('INPUT')[0])
  }
})

/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: h => h(App),
  store,
  router
});
